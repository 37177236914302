.block-minicart[class] {
  .abs-toggling-title::after,
  .abs-cart-block > .title::after,
  .minicart-items .product .toggle::after,
  .abs-discount-block .block > .title::after,
  .cart-summary .block > .title::after,
  .paypal-review-discount .block >
  .title::after,
  .cart-discount .block >
  .title::after {
    display: none;
  }

  @media (min-width: @breakpoint-s) {
    .items-total {
      margin-top: 5px;
    }
  }

  .subtotal .price-container {
    display: inline-block;
    text-align: right;
  }

  .minicart-items-wrapper {
    margin: 0;
    max-height: 65vh;
  }

  .product[class][class][class][class] {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .product-item-details {
      display: flex;
      padding-left: 0;
      align-items: center;
      width: 100%;
    }

    .options {
      display: none;
    }

    .product-item-name {
      order: 0;
      font-size: 1rem;
      font-weight: 600;
      align-items: center;
      width: 100%;
      margin: 0 3px 0 10px;
    }

    .actions {
      order: 2;
      margin: 0;
      padding: 0;
      align-items: center;
      width: 100%;
      font-size: 1rem;
    }
  }

  .minicart-items {
    .action.delete::before {
      content: 'Verwijderen';
      color: @primary;
      font-size: 1rem;
      font-family: @font-body;
      font-weight: normal;
      margin: 0;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .action.edit::before,
    .product.toggle::after,
    .product.options .toggle > span,
    .product-item-details .details-qty {
      display: none;
    }
  }

  .price-container .price {
    order: 1;
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .product-image-wrapper {
    border: 1px solid @grey30;
    border-radius: 3px;
    background: @grey10;
  }

  .product-image-photo {
    width: 75px;
    height: 75px;
  }

  .toggle, .content {
    display: none;
  }

  @media screen and (max-width: @breakpoint-md) {
    .minicart-items-wrapper {
      padding-bottom: 0;
    }

    .product-item-details {
      flex-wrap: wrap;

      .primary {
        display: none;
      }
    }

    .product-item-pricing {
      margin: 0 3px 0 10px;
    }
  }
}

.minicart-wrapper {
  .action.viewcart {
    color: @primary;
  }
}
