.onestepcheckout-index-index {
  .aw-header.aw-header-checkout {
    padding: 15px 0;
    border-bottom: 1px solid @grey30;

    @media (max-width: @breakpoint-md) {
      padding:0;
    }
  }

  .page-title {
    text-transform:uppercase;
  }

  .footer-usps {
    @media (max-width: @breakpoint-lg) {
      .footer-usp:not(:first-child) {
        display: none;
      }
    }
  }
}

