.breadcrumbs {
  &__container {
  }

  &__items {
    margin: 0;
    padding: 0;
  }

  &__item {
    position: relative;
    display: inline-block;
    margin: 0;

    a {
      color: @body-color;
    }

    &--current {
      font-weight: 600;
    }
  }

  &__divider {
    display: inline-block;
    margin: 0 0.3em;

    &--first {
      margin: 0 0.5em;

      &:after {
        position: absolute;
        top: 0rem;
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        background: @grey50;
      }
    }
  }
}
