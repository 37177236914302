@import '_addtocart.less';
@import '_accordion.less';
@import '_tabs.less';
@import '_price.less';
@import '_linkedproducts';
@import '_options.less';

.catalog-product-view {
  .usps {
    background: transparent;
    border-top: 1px solid @green-light;
    border-bottom: 1px solid @green-light;
  }
}
