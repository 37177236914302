.experience.tile__wrapper {
  .tile__title {
    font-size: 2rem;
  }

  .tile__container {
    position: relative;
  }

  .tile__actions {
    margin-top: 2rem;
  }

  .tile__content {
    padding-top: 40%;
    padding-bottom: 10%;
    text-align: center;

      @media (max-width: @breakpoint-sm) {
        padding-top: 35%;
        padding-bottom: 20%;
      }

      &::after {
      content: '';
      background: ~"url('../images/deer-experience-bg.svg') no-repeat left";
      background-position-x: -170%;
      background-position-y: 50%;
      background-size: 80%;
      opacity: 0.25;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
  }

  .button--cta {
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0.9em 1.5em;
  }
}
