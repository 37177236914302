.mpblog-post-index, .mpblog-category-view {
    .page-main {
        .columns {
            margin:15px 0 0 0;
            .column.main {
                padding-bottom:0;
            }
            .blog-overview-intro {
                h1,h2,h3,h4,h5 {
                    margin-top:0;
                    text-transform: uppercase;
                    max-width: 560px;
                    line-height: 1.3em;
                }
            }
        }
    }
    .pager {
        text-align:center;
        padding:15px;
    }
}

.blog-overview-list {
    a.product-item[class] {
        font-size:1em;
        text-decoration:none;
    }

    .product-item__image {
        height:260px;
        .img-responsive {
            width:100%;
        }
        border-bottom:3px solid @primary;
    }

    .product-item__content {
        padding-bottom:0;
    }

    .product-item__title {
        font-size:1.8rem;
        line-height:1.25em;
    }

    .product-item__bottom {
        display:block;
        .post-short-description {
            color:@black;
        }
        .post-date {
            padding-top:10px;
            color:@black;
            font-weight:600;
            font-size:0.95em;
            img {
                margin-right:5px;
                vertical-align:-2px;
            }
        }
    }
}
