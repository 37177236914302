.blog-header-wrapper {
  .blog-header-inner {
    &:after {
      content:"";
      display:block;
      position:absolute;
      right:8vw;
      top:0;
      width:50%;
      height:100%;
      background: ~"url('../Adwise_Icons/icons/watermark.svg') no-repeat top -12rem right";
      background-size:28rem;
      opacity:0.25;
    }
  }
}
