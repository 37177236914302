.aw-category__links[class][class] {
  padding: 0;
  margin: 0;
  gap: 1rem;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  max-width: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: @breakpoint-md) {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    list-style: none;
    display: inline-block;
    scroll-snap-align: start;
    padding: 0;
  }

  a {
    display: inline-block;
    padding: 0.5rem;
    background-color: @grey;
    color: @text-body-color;
    text-decoration: none !important;
    font-weight: 300;

    &:visited {
      color: @text-body-color;
    }

    &:hover {
      background-color: @primary;
      color: @white;
    }
  }
}

.aw-category__links--wrapper {
  strong {
    white-space: nowrap;
  }
}
