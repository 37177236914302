// This is an override of bootstrap-less-port/less/bootstrap-grid
// Grid system can be modified here

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "../../../../../../../../node_modules/bootstrap-less-port/less/_functions";
@import "_variables";

@import "../../../../../../../../node_modules/bootstrap-less-port/less/mixins/_breakpoints";
@import "../../../../../../../../node_modules/bootstrap-less-port/less/mixins/_grid-framework";
@import "../../../../../../../../node_modules/bootstrap-less-port/less/mixins/_grid";

@import "../../../../../../../../node_modules/bootstrap-less-port/less/_grid";
