.product-tiles-title {
  font-family: @font-branded;
  text-transform: uppercase;
  border-bottom: 1px solid @green-light;
  padding-bottom: 25px;
  margin-bottom: 35px;
  display: block;
}

.row-product-tiles {
  margin-left: -5px;
  margin-right: -5px;

  .product-item {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid @grey30;
    box-shadow: @dropshadow-0;
    transition: all .2s ease-in-out;
    text-decoration: none !important;

    &:hover {
      text-decoration: none;
      box-shadow: @dropshadow-1;
    }

    &__image[class] {
      height: 185px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        width: auto;
        height: 100%;

        &.hover {
          display: none;
        }
      }

      svg {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        max-width: 3rem;
      }
    }

    &__content {
      padding: 20px;
    }

    &__title {
      font-family: @font-branded;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: @black;
      font-size: 1.4rem;
    }

    &__description {
      font-size: 1rem;
      color: @black;

      ul {
        margin: 0 0 0 10px;
        padding-left: 15px;
      }

      ul li {
        font-size: 1rem;
        margin: 0 0 0.4rem 0;
      }
    }

    &__bottom {
      padding: 0 20px 20px 20px;
      display: flex;
      margin-top: auto;
    }

    &__price {
      margin-right: 15px;
      display: flex;

      .from {
        font-size: 0.85rem;
        font-weight: 600;
        text-transform: uppercase;
        color: @text-body-color;
      }

      .price-box {
        margin: 0;
        align-self: center;
      }
    }

    &__button {
      align-content: flex-end;
      display: flex;
      margin-left: auto;
    }

    &__cta {
      align-self: flex-end;
      .button--primary();
    }

    &__cta_alt {
      background: @btn-cta-bg;
      border: @btn-cta-bg;

      &:hover {
        background: @btn-cta-hover-bg;
        border: @btn-cta-hover-bg;
      }
    }
  }

  .product-item-hover {
    &:hover {
      .product-item__image img.hover {
        display: inline-block;

        & + img {
          display: none;
        }
      }
    }
  }

  .aw-product-slider-products.owl-carousel {
    .owl-stage {
      display: flex;
    }
  }
}
