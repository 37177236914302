.message[class] {
  &.notice,
  &.warning,
  &.progress,
  &.spinner,
  &.error,
  &.success,
  &.info {
    background: @message-bg;
    color: @message-success-color;
    font-size: 1em;
    border: 1px solid @message-border-color;
    padding: 1em;
    a {
      color: @white;
      font-weight: 600;
      text-decoration: underline;
    }

    & > *:first-child:before {
      display: none;
    }
  }

  &.success {
    border-color: @message-success-border-color;
    color: @message-success-color;
    background: @message-success-bg;
  }

  &.error {
    border-color: @message-error-border-color;
    color: @message-error-color;
    background: @message-error-bg;
  }
}
