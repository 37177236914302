.input-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;

  .input-control-wrapper {
    display: inline-flex;
    align-items: stretch;
    width: 160px;
    max-height: 45px;

    .minus, .plus {
      flex-grow: 0;
      width: 41px;
      border: 1px solid @grey70;
      padding: 0 11px;
      display: flex;

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
      }

      svg {
        margin: auto;
        width: 11px;
      }
    }

    .minus {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right: none;
    }

    .qty {
      flex-grow: 1;
      font-style: normal;
      border-radius: 0;
      padding-top: .8em;
      padding-bottom: .8em;
    }

    .plus {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-left: none;
    }
  }

  .total-price {
    font-weight: bold;

    .total-price-amount {
      font-size: 1.5em;
    }
  }
}

.product-info-main .product-add-form[class] {
  padding-top:0;
}
