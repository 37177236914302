.catalog-product-view .related-blogs {
  img.embed-responsive-item {
    object-fit: cover;
  }

  .post-item {
    color: @black-alt;

    &:hover {
      text-decoration: none;
    }
  }

  .post-title {
    font-size: 1.7rem;
    line-height: 1.25em;
    font-family: @font-branded;
    font-weight: 300;
    text-transform: uppercase;
    display: block;
    color: @black;
  }

  .post-date {
    padding-top:10px;
    color:@black;
    font-weight:600;
    font-size:0.95em;

    img {
      margin-right:5px;
      vertical-align:-2px;
    }
  }
}
