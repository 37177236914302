@import '_topbar.less';
@import '_search.less';
@import '_sooqr.less';
@import '_customer-links.less';
@import '_logo.less';
@import 'menu/_main.less';
@import 'menu/_main-mega.less';
@import 'menu/_mobile.less';
@import '_minicart.less';

.aw-header {
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: @header-bg-color;
  transform: translate3d(0,0,0);
  position: fixed;
  z-index: 900;
  transition: all .5s;
  top: 0;

  &.scrollUp {
    transform: translateY(-144px);
  }

  @media (max-width: @tablet-max-width) {
    border-bottom: 1px solid @grey30;
    padding-bottom: 3px;
  }
}

body {
  > .page-wrapper {
    padding-top:199px;

    @media (max-width: @tablet-max-width) {
      padding-top: 164px;
    }
    @media (max-width: @breakpoint-s) {
      padding-top: 140px;
    }
  }

  .breadcrumbs__container {
    padding-top: 10px;
  }

  &.onestepcheckout-index-index > .page-wrapper {
    padding-top:115px;

    @media (max-width: @breakpoint-md) {
      padding-top: 100px;
    }
  }
}


