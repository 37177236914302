.onestepcheckout-index-index {
  .step-title {
    background-color: @header-bg-color;
    color: @header-color;
    text-transform: none;
    font-size: 1.4em !important; // To override a vendor !important
  }

  .field {
    &._required > .label:after,
    &.required > .label:after {
      font-size: 1em;
      color: @base-type-color;
    }

    .label {
      span {
        font-weight: 600;
        font-size: 1.1em;
      }
    }
  }

  .input-text {
    color: @grey300;
    border-color: @grey50;
  }

  .col-mp,
  fieldset.field.col-mp {
    padding: 0 15px !important;
  }

  .one-step-checkout-container {
    margin: 0 -15px;

    @media (min-width: @breakpoint-lg) {
      display: flex;

      > .col-mp:nth-of-type(1),
      > .col-mp:nth-of-type(2) {
        border-right: 1px solid @grey30;
        display: flex;
        position: relative;
        min-height: 100%;
      }
    }
  }

  .methods-shipping {
    .table-checkout-shipping-method {
      tbody .row {
        border: 1px solid @grey30;
        display: flex;
      }

      tr {
        td.col {
          padding: 15px 10px;

          &.col-method label {
            height: 19px;
          }

          &.col-method,
          &.col-price,
          &.col-carrier {
            border: 0;
            vertical-align: middle;
            display: flex;
            font-size: 1.1rem;
          }

          &.col-carrier {
            display: none;
          }

          &.col-method {
            flex-grow: unset;
            width: unset;
            padding-left: 15px;
            padding-right: 0;
          }

          &.col-price {
            flex-grow: unset;
            order: 1;
            padding-right: 15px;
          }

          &:nth-child(3) {
            flex-grow: 1;
            width: 100%;
            padding: 15px 0;
          }
        }
      }
    }

    .row[class] {
      margin: 0 0 10px 0;
      border-radius: 3px;
      border-color: @grey30;
    }

    .col-method {
      padding-left: 15px;
    }

    .osc-place-order-block .field-row label {
      margin-bottom: 8px;
    }
  }

  .payment-methods {
    .payment-method {
      label[for="checkmo"], label[for="banktransfer"] {
        span::before {
          content: "";
          position: relative;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          vertical-align: middle;
          width: 25px;
          height: 25px;
          margin-right: 18px;
        }
      }

      label[for="checkmo"] span::before {
        background-image: ~"url('../images/money-bill.svg')";
      }

      label[for="banktransfer"] span::before {
        background-image: ~"url('../images/file-invoice.svg')";
      }
    }

    .payment-method + .payment-method .payment-method-title {
      border: 0;
    }

    .payment-method._active .payment-method-content {
      display: none;
    }

    .payment-method-title {
      padding: 15px 10px 15px 0;

      .label {
        padding-left: 3.5rem;

        .mollie-payment-icon {
          margin-right: 15px;
        }
      }
    }
  }

  .checkout-comment-block {
    padding: 0 !important;
    border: 0;

    label {
      font-weight: 600;
      font-size: 1.1em;
    }
  }

  .order-summary {
    margin-left:15px;
    margin-right:15px;
    margin-bottom:30px;
    background-color: @grey10;
    border: 1px solid @grey30;

    .step-title {
      margin-bottom: 0;
      background-color: @grey10;
      font-size: 1.2rem !important;
      border-bottom: 1px solid @grey30;
    }
  }

  .opc-block-summary[class] {
    padding: 0 15px !important;
    margin-bottom:0;
  }

  .block.items-in-cart {
    .title {
      display: none;
    }
  }

  .one-step-checkout-wrapper .mp-4 #checkout-review-table tbody tr {
    td.product {
      padding-left: 0 !important;

      .product-item-detail {
        padding-left: 0;

        .product.options {
          .toggle {
            display: none;
          }
          .content {
            display:block !important;

            .item-options[class] {
              margin-top: 0;
              display: flex;
              flex-direction: column;
              font-style:italic;

              dt {
                font-size: 1rem;
                font-weight: 300;
                font-style: normal;
              }

              dd {
                font-size:1rem;

                &.values {
                  .price {
                    &::before {
                      content:'á ';
                    }

                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    td.subtotal {
      padding-right: 0 !important;
    }
  }

  .minicart-items-wrapper {
    padding-top: 0;

    .data-table[class] {
      border: 0 !important;
      background-color: transparent !important;
      margin: 0;

      td {
        border-bottom: 0 !important;
        padding-bottom: 0 !important;
      }

      .product-item {
        padding: 0;

        .product-item-name,
        .subtotal .price {
          font-size: 1.1rem !important;
          font-weight: 700;
          margin: 0;
        }
      }
    }

    thead {
      display: none;
    }

    .details-qty,
    .button-remove {
      display: none;
    }
  }

  .table-totals[class] {
    border: 0 !important;
    margin-bottom:0;

    .totals.sub {
      border-bottom: 1px solid @grey30;
      .amount {
        font-weight: 700;
        font-size: 1.1rem;
      }
    }

    .totals.shipping,
    .totals-tax-summary {
      border-bottom: 1px solid @grey30;
    }

    .totals-tax-summary {
      .mark, .amount {
        border:0;
        cursor: default;
      }

      .price {
        padding-right:0;

        &::after {
          display: none;
        }
      }
    }

    .totals-tax-details {
      border-bottom: 0;
      display: table-row !important;
    }

    .grand.totals .mark {
      font-size: 1.1rem;
      padding-top: 15px;

      strong {
        font-weight: 400;
      }
    }

    .grand.totals.incl {
      border-top: 1px solid @grey30;
    }

    .grand.totals.excl {
      display: none;
    }

    thead th,
    tr th {
      background-color: transparent;
      text-align: left;
    }

    tr td {
      padding-right: 0;
    }

    tr {
      th, td {
        border-top: 0 !important;
      }
    }
  }

  .opc-estimated-wrapper {
    display: none;
  }
}
