.product-usps,
.carousel-content,
.account-usps {
  ul {
    padding-left: 25px;

    li {
      position: relative;
      list-style: none;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0.1rem;
        left: -2.1rem;
        box-sizing: border-box;
        pointer-events: none;
        width: 18px;
        height: 18px;
        z-index: 2;
        background-color: @primary;
        mask-image: ~"url('../Adwise_Icons/icons/check-circle.svg')";
        background-size: 75% 75%;
        background-position: center center;
        background-repeat: no-repeat;
        transition: transform 0.1s ease;
        transform: scale(0.8);
      }
    }
  }
}
