.modal-popup .modal-footer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-slide .action-close {
  padding: 20px;
}

.modal-popup .modal-footer .action-hide-popup[class] {
  color: @grey100;

  &:hover {
    color: @grey200;
  }
}

.modal-popup[class] {
  z-index:999;
}

.modal-header {
  .action-close {
    display: none;
  }
}

.modal-footer button {
  .button--primary();
}
