.aw-customer-links {
  .aw-customer-dropdown {
    &__heading {
      color: @text-body-color;
      text-transform: uppercase;
      font-size: 1.4rem;
    }

    &__body {
      .button {
        .button--primary();
      }

      .customer-dropdown-links {
        padding: 0 15px 15px 15px;

        li {
          border-bottom: 1px solid @grey30;

          &:first-child {
            border-top: 1px solid @grey30;
          }

          a {
            width: 100%;
            padding: 8px 0;
            display: flex;

            span {
              margin-left:auto;

              svg {
                fill: @grey100;
              }
            }
          }
        }
      }
    }

    &__footer {
      a {
        color: @grey300;
      }
    }
  }
}
