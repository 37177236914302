.experience.product-slider {
  .tile__wrapper a.tile__container {
    text-decoration: none;
  }

  .tile__content {
    padding: 0 1.5rem 1.5rem;
  }

  .tile__title {
    font-family:@font-branded;
  }

  .owl-carousel {
    .owl-stage {
      display: flex;
    }
    .owl-stage-outer {
      overflow: hidden;
    }
    .owl-dots,
    .owl-nav.disabled {
      display: none;
    }
  }
}
