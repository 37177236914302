.form-postcodenl {

  .postcode-validation-result {
    position: relative;
    border: 0;
    padding: 0;
    line-height: 1.7em;
  }

  .fieldset:last-child {
    margin-bottom: 0;
  }

  .message.warning {
    margin: 0 0 20px;
  }

  .manual-link {
    border: 0;
    padding: 10px 0 20px;
    font-size: 0.9em;
  }

  .change-link {
    cursor: pointer;
    color: @primary;
    text-decoration:underline;
  }

  .manual {

    .field {
      margin: 0 0 20px;

      .label {
        display: block;
        margin: 0 0 8px;
      }

      &.required {

        .label:after {
          content: '*';
          font-size: 1em;
          color: @black;
          margin: 0 0 0 5px;
        }
      }
    }
  }
}
