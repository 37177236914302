// Credit: Nicolas Gallagher and SUIT CSS.

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;

	&::before {
		display: block;
		content: "";
	}

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.embed-responsive-21by9 {
	&::before {
		padding-top: percentage((9 / 21));
	}
}

.embed-responsive-16by9 {
	&::before {
		padding-top: percentage((9 / 16));
	}
}

.embed-responsive-4by3 {
	&::before {
		padding-top: percentage((3 / 4));
	}
}

.embed-responsive-1by1 {
	&::before {
		padding-top: percentage((1 / 1));
	}
}
