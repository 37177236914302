.fbc__rating {
  position: relative;
  margin-bottom: 15px;
}

.fbc__result {
  display: inline-block;
  position: relative;
  font-size: 0;
  white-space:nowrap;
  margin-bottom: 15px;

  svg {
    display: inline-block;
    fill: @green-light;
    height: 1.5rem;
    margin-right: 0.2rem;
  }

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    svg {
      fill: @white;
    }
  }
}

.fbc__rating.horizontal {
  text-align: center;
  margin-bottom: 0;

  a {
    color: @primary;
    font-weight: 600;
  }

  .fbc__result {
    margin-bottom: 0;

    svg {
      fill: @grey50;
    }

    & > span {
      svg {
        fill: @orange;
      }
    }
  }
}

.aw-header .fbc__rating {
  margin-bottom: 0;
  display: flex;
  margin-left: 0.5rem;

  .fbc__result {
    margin-bottom: 0;
    display: block;

    & > svg,
    & > span svg {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  .fbc__summary a {
    text-transform: none;
    text-decoration: none;
    font-weight: @base-type-weight;
    transition: none;
    letter-spacing: 0;

    &:hover {
      background-color: transparent;
      color: @white;
      text-decoration: underline;
    }
  }
}
