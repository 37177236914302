.aw-mainmenu-mega[class] {
  > .aw-topmenu__dropdown {
    padding: 15px 0;
  }

  > .aw-topmenu__topic-link[class] {
    background-color: @secondary;
    color: @white;
    padding: 1.2em 1em 1em;
    cursor: pointer;

    svg {
      transform: rotate(90deg);
      margin-left: 5px;

      path {
        fill: @white;
      }
    }
  }

  &:hover > .aw-topmenu__topic-link[class] {
    background-color: @primary;

    svg {
      transform: rotate(-90deg);
    }
  }

  .sidebar-dropdown {
    display: none;
    position: absolute;
    top: 0;
    left: 20%;
    width: 80%;
    border-left: 1px solid @grey15;
    height: calc(100% - 3px);
    padding: 45px 0 15px 15px;
  }

  .dropdown-close {
    right: 0;
    font-weight: normal;
  }

  .sidebar-item {
    margin-bottom: 0;
    padding: 8px;
    width: 20%;


    &:hover {
      background-color: @grey15;

      .sidebar-dropdown {
        display: block;
      }
    }
  }

  .mega-item-link {
    color: @black;

    &:hover {
      color: @primary;
    }
  }

  .mega-subitem-link {
    color: @black-alt;

    &:hover {
      color: @primary;
    }
  }
}
