.experience.instruction {
  .instruction-navigation-step {
    &.current {
      color: @green;

      .instruction-navigation-step-bg {
        background-color: @green-light;

        &::before {
          background-color: @green-lighter;
        }
      }
    }
  }
}
