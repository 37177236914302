.tile {
  &__container {
    height: 100%;
    border: 1px solid @grey30;
    box-shadow: @dropshadow-0;
    transition: all 0.2s ease;
    text-decoration: none;

    &:hover {
      box-shadow: @dropshadow-1;
      border-color: @grey50;
      text-decoration: none;

      .button {
        background-color: @btn-hover-bg;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    padding-bottom: 60%;
    cursor: pointer;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 1.5rem;

    strong {
      display: block;
      text-transform: uppercase;
      font-family: @font-body;
      font-weight: 700;
      font-size: 0.7rem;
    }

    .category-usps {
      ul {
        padding-left: 1.5rem;

        li {
          margin-bottom: 0;
          font-size: 1.1rem;
        }
      }

      a {
        .generic-link();
        color: @primary;
        text-decoration: underline;

        &:hover {
          color: @primary;
        }

        &:visited {
          color: @primary;
        }
      }
    }
  }

  &__title {
    .h6();
    text-transform:uppercase;
    margin: 0 0 1rem 0;
    color: @black;
  }

  &__price {
    color: @primary;
    font-family: @font-branded;
    font-weight: 500;
    font-size: 1.6rem;
  }

  &__actions {
    text-align: right;

    .button {
      font-family: @font-body;
      font-size: 0.9rem;
    }
  }
}
