a.readmore-link,
a.product-description-readmore-link,
.product.attribute.overview a {
  color: @primary;
  text-decoration: underline;
  font-size: 1.1rem;

  &:hover {
    text-decoration: none;
  }
}

.pdp-product-description,
.pdp-product-supplied-with{
  line-height: 1.6em;

  h2 {
    .page-block-title();
  }

  a[href*=".pdf"] {
    .button--secondary();
  }

  ul li {
    margin-bottom: 0.75rem;
  }

  table > * > tr {
    > th:first-child,
    > td:first-child {
      width: 40%;
    }

    th, td {
      padding: 1px;
    }
  }
}
