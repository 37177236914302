// Most styles are already reset by Normalize
//
// @link https://github.com/necolas/normalize.css/blob/master/normalize.css#L250
// @link https://github.com/inuitcss/objects.buttons/blob/master/_objects.buttons.scss
//
// 1. Allow us to style box model properties.
// 2. Line different sized buttons up a little nicer.
// 3. Reset/normalize some styles.
// 4. Force hand-pointer when using on non-btn-like elements like <span/>

.button {
  display:inline-block; // [1]
  border:none; // [3]
  border-radius:0; // [3]
  appearance:none;
  vertical-align:top; // [2]
  text-align:center; // [3]
  text-decoration:none !important; // [3]
  line-height:1; // [3]
  cursor:pointer; // [4]
  font-family:@font-body;
  font-weight: 400;
  background:none;
  box-shadow:none;
  padding: 1em 2em;
  background-color: @btn-bg;
  color: @btn-color;
  font-size: 1.1rem;
  height: auto; // reset Luma

  &:focus {
    outline: 1px dotted @btn-outline-color;
  }

  &:hover,
  &:focus,
  &:active {
    border: none; // Override Luma
    background-color: @btn-hover-bg;
    text-decoration: none;
    color: @btn-color;
  }

  & svg {
    position: relative;
    top: 0.1em;
    display: inline-block;
    height: 0.8em;
    line-height: 0.8em;
    width: auto;
    fill: @btn-color;
    margin-left: 0.5em;
  }
}

.button--primary {
  .button();
  background-color:@btn-bg;
  color:@btn-color;
  border: none; // reset Luma
  font-weight: 600;
  padding: 0.45em 1em;
  font-size: 1.1rem;

  &:hover,
  &:focus,
  &:active {
    border: none; // reset Luma
    background-color:@btn-hover-bg;
    color: @btn-color;
  }
}

.button--secondary {
  .button();
  background-color:@btn-secondary-bg;
  color:@btn-secondary-color;
  border: none; // reset Luma
  font-weight: 600;
  padding: 0.45em 1em;
  font-size: 1.1rem;

  &:hover,
  &:focus,
  &:active {
    border: none; // reset Luma
    background-color:@btn-secondary-hover-bg;
    color: @btn-secondary-color;
  }

  &[class] > svg {
    fill: @btn-secondary-color;
  }
}

.button--cta {
  .button();
  background-color:@btn-cta-bg;
  color:@btn-cta-color;
  border: none; // reset Luma
  font-weight: 600;
  padding: 0.45em 1em;
  font-size: 1.1rem;

  &:hover,
  &:focus,
  &:active {
    border: none; // reset Luma
    background-color:@btn-cta-hover-bg;
    color: @btn-cta-color;
  }

  & > svg {
    fill: @btn-cta-color;
  }
}

.button--large {
  width: 100%;
  height: auto; // FU luma
  line-height: 1;
  font-size: 1rem;
  padding: 2em;
}

// LUMA, I COMMAND THEE, SURRENDER TO MY POWERS
.action.primary[class],
.actions .primary .action.primary,
.actions-toolbar .primary .action-apply,
#confirmButtons .action.primary,
.cart-discount .coupon .primary .action.primary,
.block-authentication .action.action-login,
.form-address-edit .actions-toolbar .action.primary {
  .button--primary();
}

.action.primary.tocart[class][class][class],
.action.primary.checkout[class][class][class] {
  .button--cta();

  svg {
    font-size:1.5rem;
    margin-right:10px;
  }
}

button,
#confirmButtons .button.secondary {
  .button();
}

button:not(.primary) {
  &,
  &:active {
    box-shadow: none; // reset Luma
  }
}
