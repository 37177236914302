.input-control {
  .input-control-wrapper {
    .minus, .plus {
      border-color: @green-light;

      &.disabled {
        color: #333;
        background-color: @form-input-border-color-disabled;
      }
    }

    .qty {
      color: @text-body-color;
      border-color: @green-light;
    }
  }
}

.product-info-main .product-options-bottom .box-tocart {
  margin-bottom: 0;

  .action.tocart:not(:last-child),
  .fieldset:last-child {
    margin-bottom: 0;
  }

}