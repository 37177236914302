@media (max-width: (@tablet-max-width - 1px)) {
  .aw-topmenu {
    &__topics {
      padding: 0;
      margin: 0;
      background-color: @white;
    }

    &__topic { // li
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-bottom: 1px solid @grey30;
      margin: 0;

      &:last-child {
        border-bottom: 0;
      }

      &-link {
        display: flex;
        width: 100%;
        padding: 18px 0;
        color: @black;
        font-weight: 600;

        &:active,
        &:hover {
          background: @grey;
        }

        & > span {
          display: block;
          align-self: center;
        }
      }

      a.aw-topmenu__topic-link {
        display: none;
      }

      &-image {
        display: block;
        height: 3em;
        width: 4em;
        margin-right: 1em;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

      }
    }

    .aw-topmenu__backlink,
    .aw-topmenu__backlink-level-2 {
      display: none;
      width: 100%;
      padding: 10px 0;
      align-items: center;
      background-color: @white;

      svg {
        transform: rotate(180deg);
        margin-right: 10px;

        path {
          fill: @primary;
        }
      }
    }

    .aw-topmenu__backlink-level-2 {
      width: calc(100% + 20px);
      margin: 0 -10px;
    }

    &__toggler,
    &__icon {
      position: absolute;
      right: 0;
      top: 1rem;
      height: 4em;
      width: 3rem;
      display: flex;

      svg {
        display: block;
        align-self: center;
        margin: auto;

        path {
          fill: @primary;
        }
      }
    }

    &__icon {
      pointer-events: none;
    }

    &__dropdown {
      display: none;
      background: @navigation-bg;
      width: 100%;

      &.open {
        display: block;
      }
    }

    &__sections {
      padding: 0 10px;
    }

    &__section {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 0;

      &:last-child .aw-topmenu__title {
        border: 0;
      }

      &-image {
        display: block;
        height: 3em;
        width: 4em;
        margin-right: 1em;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      a {
        display: flex;
        padding: 0.5em 1em;
      }
    }

    .aw-topmenu__link {
      border-bottom: 1px solid @grey30;
      padding: 15px 0;
    }

    .aw-topmenu__item {
      margin-bottom: 0;

      &:last-child .aw-topmenu__link {
        border-color: transparent;
      }
    }

    .aw-topmenu__items {
      padding: 0;
      display: none;
    }

    &__title {
      border-bottom: 1px solid @grey30;
      padding: 15px 0;

      &-label {
        display: block;
        align-self: center;
      }
    }

    a.aw-topmenu__title {
      display: none;
      background-color: @white;
      padding: 18px 0;
      color: @grey300;
      font-weight: 600;
    }

    a {
      text-decoration: none;
    }
  }

  html.menu-active {
    &.menu-active-level-1 {
      .aw-topmenu__topic:not(.open) {
        display: none;
      }

      .aw-topmenu__topic {
        border-color: transparent;
      }
    }

    &.menu-active-level-2 {
      .aw-topmenu__topic.open > .aw-topmenu__topic-link,
      .aw-topmenu__section:not(.open) {
        display: none;
      }

      a.aw-topmenu__title {
        border-color: transparent;
      }
    }
  }

  .aw-topmenu__topic.open {
    div.aw-topmenu__topic-link,
    .aw-topmenu__icon {
      display: none;
    }

    .aw-topmenu__dropdown,
    a.aw-topmenu__topic-link {
      display: block;
    }

    .aw-topmenu__backlink {
      display: flex;
    }
  }

  .aw-topmenu__section.open {
    div.aw-topmenu__title {
      display: none;
    }

    .aw-topmenu__items,
    a.aw-topmenu__title {
      display: block;
    }

    a.aw-topmenu__title {
      margin: 0 -10px;
      padding: 10px 0;
    }

    .aw-topmenu__backlink-level-2 {
      display: flex;
    }
  }

  html.menu-active,
  html.menu-active body {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .aw-navigation__mobile {
    display: block;
    position: fixed;
    left: -100%;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    background: @navigation-bg;
    overflow-y: scroll;
    padding-bottom: 1.5rem;
    transition: left 0.2s ease;
  }

  .menu-active .aw-navigation__mobile {
    left: 0;
  }
}

.aw-navtoggle {
  &__icon {
    svg {
      width: 25px;
      height: 25px;
      margin-top: 10px;
      fill: @primary;
    }

    &--opened {
      display: none;
    }

    &--closed {
      display: block;
    }
  }
}

.menu-active .aw-navtoggle {
  &__icon {
    &--opened {
      display: block;
    }

    &--closed {
      display: none;
    }
  }
}
