.page-layout-contest_page {
  div[data-offer] {
    display: none;
  }

  h1 {
    font-family: @font-branded;
    color: @green;
    text-transform: uppercase;
    font-size: 5rem;

    @media (max-width: @breakpoint-md) {
      font-size: 3rem;
    }
  }

  .garden-of-fame-logo {
    @media (max-width: @breakpoint-md) {
      width: 25%;
    }
    @media (max-width: @breakpoint-sm) {
      width: 45%;
    }
  }

  .woobox-loading {
    img {
      width: 50px;
    }

    h3 {
      color: @black;
      font-size: 1.2rem;
    }
  }

  iframe {
    position: relative !important;
  }

  .garden-of-fame-participate {
    display: none;
  }
}
