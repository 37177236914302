.toolbar-wrapper {
  &-top {
    @media (max-width: @breakpoint-lg) {
      .toolbar-amount {
        display: none !important;
      }
    }
    @media (max-width: @breakpoint-md) {
      .pages {
        .d-none();
      }
    }
  }

  &-bottom {
    .toolbar-sorter,
    .toolbar-amount {
      .d-none();
    }
  }
}

.mpblog-post-index .pager,
.toolbar.toolbar-products {
  margin-top: 30px;
  color: @text-body-color;

  @media (max-width: @breakpoint-md) {
    .toolbar-amount-wrapper[class][class][class] {
      display: none;
    }

    margin-left: -15px;
    margin-right: -15px;
  }

  .pages[class] {
    padding: 10px 0 7px 0;
    background-color: @white;
    text-align: center;
    display: block;
    margin: 0 0 0 auto;

    .items {
      padding: 0;
    }

    .item {
      position:relative;
    }

    .item > .page {
      .button--secondary();
      color: @text-body-color;
      padding: .75em .7em;

      .pager-icon {
        svg {
          fill: @text-body-color;
          margin:0;
        }
      }

      &.link-prev .pager-icon svg {
        transform: rotate(180deg);
      }

      &:visited {
        color: @text-body-color;
      }
    }

    .item.current > .page {
      color: @white;
      background-color: @primary;
      cursor: default;
    }
  }

  .toolbar-sorter {
    float: none;
    position: static;
    max-width: 300px;

    .sorter-options {
      .button--secondary();

      padding: .5em 1.5em .5em 1em;
      color: @text-body-color;
      position: relative;
      line-height:21px;

      @media (max-width: @breakpoint-md) {
        margin-left: 0;
      }
    }

    .sorter-options-wrapper {
      position: relative;

      @media (max-width: @breakpoint-md) {
        width: 100%;
        select {
          width: 100%;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 5;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: @grey200 transparent transparent transparent;
      }
    }
  }

  .toolbar-amount {
    float: none;
    text-align: right;

    .toolbar-number {
      font-weight: 600;
    }
  }
}

.mpblog-post-index .pager .pages[class] {
  text-align: right;
}
