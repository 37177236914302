html,
body {
  font-family: @font-body;
  font-size: 14px;
  color: @base-type-color;
  font-weight: @base-type-weight;
}

.generic-link {
  color: @base-link-color;
  text-decoration: none;
  font-weight: @base-type-weight;

  &:hover {
    color: @base-link-color;
    text-decoration: underline;
  }

  &:visited {
    color: @base-link-color;
    text-decoration: none;
    font-weight: @base-type-weight;
  }
}

a {
  .generic-link();
}

.text-link {
  font-family:@font-body;
  font-weight:400;
  color: @base-link-color;

  &::after {
    content:'>';
    margin-left:.3em;
  }
}

.page-block-title {
  font-family: @font-branded;
  font-weight: 300;
  margin: 0 0 1rem;
  display: block;
  line-height: 1.1;
  font-size: 1.8rem;
}

.aw-content[class] a:not(.button--secondary, .button--primary, .button-cta) {
  color: @primary;
  text-decoration: underline;
  font-size: 1rem;

  &:hover {
    text-decoration: none;
  }
}

.h1 {
  font-family:@font-branded;
  font-size:3rem;
  font-weight: 300;
  margin-bottom: 0.5em;

  @media (max-width: @breakpoint-md) {
    font-size: 2rem;
  }
}

.h2 {
  font-family:@font-branded;
  font-size:2rem;
  font-weight:300;
  margin-bottom: 0.5em;

  @media (max-width: @breakpoint-md) {
    font-size: 1.5rem;
  }
}

.h3 {
  font-family:@font-branded;
  font-size:1.4rem;
  font-weight:700;
  margin-bottom: 1em;

  @media (max-width: @breakpoint-md) {
    font-size: 1.15rem;
  }
}

.h4 {
  .h3();
}

.h5 {
  .h3();
}

.h6 {
  .h3();
}

.screen-reader-text {
  position:absolute;
  clip:rect(1px, 1px, 1px, 1px);
  width:1px;
  height:1px;
  overflow:hidden;
}

.truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  word-wrap: normal;
  display: block;
  white-space: nowrap;
}

.unstyle-list {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.page-title {
  .h1();
}

.modal-title {
  .h1();
  font-size: 2rem;
}

.iconcheck {
  position:relative;
  top:2px;
  margin:0 5px 0 0;
  width:15px;
  fill: @usp-fill-color;
}

.page-main .block .block-title strong,
.fieldset > .legend > span{
  .h5();
}

.abs-account-blocks .block-title > strong,
.abs-account-blocks .block-title > span,
.account .legend > strong,
.account .legend > span,
.form-giftregistry-search .legend > strong,
.form-giftregistry-search .legend > span,
.block-giftregistry-results .block-title > strong,
.block-giftregistry-results .block-title > span,
.block-giftregistry-shared-items .block-title > strong,
.block-giftregistry-shared-items .block-title > span,
.block-wishlist-search-form .block-title > strong,
.block-wishlist-search-form .block-title > span,
.block-wishlist-search-results .block-title > strong,
.block-wishlist-search-results .block-title > span,
.multicheckout .block-title > strong,
.multicheckout .block-title > span,
.multicheckout .block-content .title > strong,
.multicheckout .block-content .title > span,
.customer-review .review-details .title > strong,
.customer-review .review-details .title > span,
.paypal-review .block .block-title > strong,
.paypal-review .block .block-title > span,
.account .column.main .block:not(.widget) .block-title > strong,
.account .column.main .block:not(.widget) .block-title > span,
.multicheckout .block-title > strong,
.multicheckout .block-title > span,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title > strong,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title > span,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > span,
.sales-guest-view .column.main .block:not(.widget) .block-title > strong,
.sales-guest-view .column.main .block:not(.widget) .block-title > span {
  .h2();
}
