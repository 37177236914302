.aw-customer-links {
  .unstyle-list();
}

.aw-customer-link--group {
  position: relative;
}

.aw-customer-link {
  display: block;
  position: relative;
  font-weight: 400;
  text-align: center;
  background-color: @white;
  height: 100%;
  padding: 0 0.5em;

  &.active {
    z-index: 100;
  }

  &:hover {
    text-decoration:none;
  }
}

.aw-customer-link__label {
  white-space: nowrap;
  color: @black;

  svg {
    fill: @primary;
    width:22px;
    height:22px;
    margin-bottom:3px;
  }
}

.aw-customer-link__dropdown {
  display: none;
}

.aw-customer-link__dropdown.open {
  display: block;
}

.aw-customer-dropdown-wrapper {
  background: @white;
  color: @grey;
  border-style: solid;
  border-width: 0 0 3px 0;
  border-color: @component-border-bottom-color;
  margin: 0;
  list-style: none;
  z-index: 101;
  position: absolute;
  top: 100%;
  right: -50px;
  width: 300px;
  box-shadow: @dropshadow-2;

  &::before {
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 3.5em;
    height: .5em;
    background: white;
  }

  ul {
    .unstyle-list();
  }

  li {
    padding: 5px 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0; // reset Luma
    }
  }
}

.aw-customer-dropdown__heading {
  .h2();
  display: block;
  color: @blue;
  padding: 1rem;
  margin-bottom: 0;
}

.aw-customer-dropdown--is-logged-out .aw-customer-dropdown__body {
  padding: 1em;
}

.customer-dropdown-links {
  > li {
    margin-bottom: 0;
    padding: 0;
  }
}

.aw-customer-dropdown__footer {
  padding: 1em;
  font-size: 0.875em;
  color:@grey300;

  a {
    color: @blue;
    font-weight: 600;
  }
}
