.customer-account-login,
.customer-account-create {
  .column.main {
    padding: 0 15px;
  }
  .page-title {
    text-transform: uppercase;
    font-size: 2.6rem;
  }

  .login-container {
    .block-title strong {
      font-size:1.8rem;
      text-transform: uppercase;
    }
  }

  .legend {
    font-size:1.8rem;
    text-transform: uppercase;
  }
}

.customer-account-login .login-container .block-title strong,
.customer-account-create .login-container .block-title strong {
  color: @black;
}