.checkout-cart-index {
  .cart-summary {
    border:1px solid @green-light;
    background-color: @green-lighter;
  }
  .cart-summary > .title{
    border-bottom:1px solid @green-light;
  }
  .cart-totals[class] {
    .totals.sub,
    .totals-tax-summary {
      border-bottom: 1px solid @green-light;
    }

    .grand.totals.incl {
      border-top-color: @green-light;
    }
  }
  .cart-summary {
    .totals.sub.excl,
    .totals.sub.incl,
    .totals-tax {
      border-bottom: 1px solid @green-light;
    }
  }
}
