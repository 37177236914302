/** Load origins base **/
@import '../../base/styling/theme.less';
@import 'components/_base.less';
@import 'components/header/main.less';
@import 'components/footer/main.less';
@import 'components/pdp/main.less';
@import 'components/_typography.less';
@import 'components/_button.less';
@import 'components/_usps.less';
@import 'components/_hero.less';
@import 'components/_home.less';
@import 'components/_inspiration.less';
@import 'components/category/main.less';
@import 'components/cart/main.less';
@import 'components/customer/main.less';
@import 'components/_team.less';
@import 'components/configurator/main.less';
@import 'components/checkout/main.less';
@import 'components/blog/_header.less';
@import 'components/cms/main.less';

/** override the settings **/
@import '_settings.less';

.vdt__roundedcorner {
  position:absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  border-bottom-right-radius: 1.5rem;
  z-index: -1;
}

.aw-tabs .tab-content {
  @media screen and (min-width: @breakpoint-lg) {
    line-height: 1.6em;
  }
}
