.aw-footer {
  .footer-top {
    border-top: 1px solid @green-light;
  }
}

.footer-menu__item {
  @media (max-width: @breakpoint-sm) {
    a {
      border-color: @green-light;
    }
  }
}

.footer-middle .footer-socials svg {
  fill: @white;
}

.footer-bottom {
  .footer-certifications,
  .footer-bottom-menu {
    border-color: @green-light;
  }
}