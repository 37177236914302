.aw-hero {
  &__image {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    padding-left: inherit;
    padding-right: inherit;

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__group[class] {
    display: flex;
    min-height: 40rem;

    .aw-hero__button {
      margin: 2rem auto 0 0;
      z-index: 2;
    }

    .aw-hero__content {
      left: 0;
      align-self: center;
      padding: 4rem 2rem;

      @media (min-width: @breakpoint-md) {
        margin: 0 2rem;
      }
    }

    @media (max-width: @breakpoint-md) {
      min-height: 0;
      padding: 0;
    }
  }

  &__content {
    display: flex;
    align-self: center;
    position: relative;
    left: 3rem;
    top: 0;
    bottom: 0;
    width: 80%;
    background: @primary;
    color: @white;
    padding: 2rem 4rem 2rem 2rem;
    flex-direction: column;

    @media (max-width: @breakpoint-md) {
      background: transparent;
      left: 0;
      width: 100%;
      padding: 3rem;
    }
  }

  &__title {
    .h2();
    text-transform: uppercase;
    margin: 0;
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  &__button {
    .button--primary();
    color: @white;
    padding: 0.7em 1em;
    margin-top: 2rem;
    border: 0;
  }
}

.aw-home__hero {
  .col-4.aw-hero__group .aw-hero__content {
    align-self: flex-end;
    padding: 6rem 2rem;
    margin: 0;
  }

  .row {
    margin-right: unset;
    margin-left: unset;
  }

  .carousel-container {
    position: relative;

    .owl-dots {
      display: none;
    }

    .carousel-indicator {
      color: @white;
      position: absolute;
      z-index: 2;
      left: 30px;
      bottom: 30px;
      height: 30px;

      .carousel-index {
        font-weight: 700;
      }

      .carousel-separator {
        width: 1px;
        background-color: @white;
      }
    }

    .carousel-navigation {
      position: absolute;
      z-index: 2;
      right: 30px;
      bottom: 30px;

      .carousel-navigation-button {
        border-radius: 5px;
        width: 44px;
        height: 44px;
        cursor: pointer;
        background-color: @primary;

        svg {
          fill: @white;
          height: 10px;
          width: 10px;
        }

        &.prev svg {
          transform: rotate(180deg);
        }
      }
    }

    @media (max-width: @breakpoint-md) {
      margin-bottom: 64px;

      .owl-stage {
        display: flex;
      }

      .item {
        height: 100%;

        .aw-hero__group {
          height: 100%;

          .aw-hero__content {
            height: 100%;
          }
        }
      }

      .carousel-indicator {
        left: 0;
        bottom: -44px;
        color: @black-alt;

        .carousel-separator {
          background-color: @primary;
        }
      }

      .carousel-navigation {
        bottom: -54px;
        right: 0;

        .carousel-navigation-button {
          border: 1px solid @primary;
          background-color: @white;

          svg {
            fill: @primary;
          }
        }
      }
    }
  }
}
