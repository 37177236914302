// Typography

#text-emphasis-variant(@parent, @color) {
	@{parent} {
		color: @color !important;
	}
	a@{parent} {
		#hover-focus({
			color: darken(@color, 10%) !important;
		});
	}
}
