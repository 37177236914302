.catalog-product-view .product-rating {
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  .stars {
    position: relative;
    white-space: nowrap;
    border-radius: 50%;

    svg {
      padding: 4px;
      border-radius: 50%;
      vertical-align: middle;
      background-color: @grey50;
      fill: @white;
    }

    > span {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;

      svg {
        background-color: @green;
      }
    }
  }

  .review-amount {
    color: @base-type-color;
  }
}

.product-reviews {
  .info .date {
    min-width: fit-content;
  }

  .rating {
    .stars {
      position: relative;
      white-space: nowrap;

      > span {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;

        svg {
          fill: @orange;
        }
      }

      svg {
        fill: @grey50;
      }
    }
  }

  .text-content {
    min-height: 3em;
    font-size: 1rem;

    h5 {
      font-size: 1rem;
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .read-more {
    margin-top: 0.5rem;
    color: @primary;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.875rem;

    svg {
      transform: rotate(90deg);
      margin: 2px 5px 0 0;
      width: 10px;

      path {
        fill: @primary;
      }
    }
  }
}
