// Colors
@white-smoke: #f2f7f6;
@black: #412413;
@black-alt: #000000;
@orange: #eeaf27;
@yellow: @orange;
@orange-dark: darken(@orange, 10%);
@orange10: #ffece5;
@red: #ee2200;
@red-light: #eb5d4e;
@green: #1ec499;
@green-dark: darken(@green, 10);
@green-light: #c5e0da;
@green-lighter: #F7FCFB;
@green-dark: darken(@green, 10%);
@white: #ffffff;

// Fonts
@font-branded: 'Oswald', 'Avenir Next Condensed', 'Arial Narrow', 'MS UI Gothic', sans-serif;
@font-body: 'Open Sans', sans-serif;

/* -------------------------------------------------------------- */

@primary: @green;
@secondary: @orange;
@alert: @orange;

@base-page-bg-color: @white;
@base-type-color: @black;
@base-type-weight: 300;
@base-link-color: @grey300;
@text-dimmed-color: @grey200; // old-price

// Navigation - mobile
@m-navigation-link-bg: @grey100;
@m-navigation-topic-bg: @grey100;
@m-navigation-dropdown-bg: @grey100;
@m-navigation-title-bg: @white;
@m-navigation-items-bg: @grey70;
@m-navigation-item-color: @white;

// Messages
@message-bg: @orange;
@message-border-color: @orange;
@message-error-bg: @red-light;
@message-error-border-color: @red-light;

// Components
@component-color: @grey300;
@component-bg-color: @grey15;
@component-border-color: @grey30;
@component-border-color-accent: @grey50;
@component-border-bottom-color: @green;

// CTA's
@cta-bg: @red;
@cta-color: @white;

// Minicart
@minicart-qty-bg: @green;

// Search
@search-bg: @grey15;
@search-border-color: @grey200;
@search-autocomplete-border-color: @grey15;

// Topbar
@topbar-color: @white;
@topbar-bg: @green;
@topbar-border-color: @white;
@topbar-link-color: @white;
@topbar-link-color-mobile: @black;
@topbar-accent-color: @orange;
@topbar-hover-color: @green;

// USP's
@usp-fill-color: @green;
@home-usps-bg: @component-bg-color;
@home-usps-color: @grey300;
@home-usps-item-bg: @grey30;
@home-usps-icon-bg: @grey100;
@home-usps-icon-color: @white;

// Home banners
@home-minibanners-bg: @grey30;
@home-minibanners-link-bg: @grey30;
@home-minibanners-link-color: @white;

// Slider
@slider-progress-dot-bg: @grey300;
@slider-progress-dot-active-bg: @black;

// Header
@header-bg-color: @white;
@header-color: @black;
@header-dropdown-bg: @white;
@header-dropdown-border-color: @grey70;

// CMS
@tree-link-color: @black;
@tree-toggler-color: @grey300;
@tree-item-lvl2-color: @black;

@m-tree-link-color: @grey15;
@m-tree-bg: @grey30;
@m-tree-link-lvl1-color: @grey30;
@m-tree-link-lvl2-color: @grey15;
@m-tree-lvl1-bg: @grey15;
@m-tree-lvl2-bg: @grey15;

// Account
@account-menu-bg: @white;
@account-menu-border-color: @grey70;

// Datepicker
@datepicker-navigation-color: @black;
@datepicker-bg: @white;
@datepicker-bg--disabled: @grey30;
@datepicker-border-color: @grey300;
@datepicker-color: @black;
@datepicker-color--disabled: @grey300;
@datepicker-cell-border-color: @grey30;
@datepicker-cell-bg--active: @yellow;
@datepicker-cell-today-bg: @grey50;

// Colorvariants
@color-variants-base-color: @black;
@color-variants-border-color: @component-border-color;
@color-variants-border-color-hover: @component-border-color-accent;
@color-variants-icon-color: @grey300;

// Button
@btn-color: @white;
@btn-bg: @green;
@btn-hover-bg: @green-dark;
@btn-outline-color: @green-dark;

@btn-secondary-color: @green;
@btn-secondary-bg: transparent;
@btn-secondary-hover-bg: @grey30;
@btn-secondary-outline-color: @green;

@btn-cta-color: @white;
@btn-cta-bg: @orange;
@btn-cta-hover-bg: @orange-dark;

// Breadcrumbs
@breadcrumb-item-bg: @grey15;
@breadcrumb-item-color: @grey300;
@breadcrumb-icon-color: @grey300;
@breadcrumb-chevron-color: @base-link-color;
@breadcrumb-link-color: @base-link-color;
@breadcrumb-link-current-color: @grey300;
@breadcrumb-link-hover-bg: @grey50;

// Modals
@confirmbox-header-bg: @grey30;

// Footer
@footer-top-bg: @primary;
@footer-top-color: @white;
@footer-mid-bg: @green;
@footer-mid-color: @white;
@footer-bot-bg: @white;
@footer-bot-color: @primary;
@footer-payment-bg: @white;
@footer-newsletter-border-color: @grey200;
@footer-newsletter-button-bg: @black;
@footer-newsletter-button-bg--hover: @grey300;

// Pagination
@pagination-bg: @grey15;
@pagination-color: @black;
@pagination-color--disabled: @grey50;

// Product grid
@productgrid-link-border-color: @grey30;
@productgrid-link-border-color--hover: @grey100;
@productgrid-link-bg: @white;
@productgrid-brand-color: @grey200;
@productgrid-name-color: @black;

// Filters
@filter-item-border-color: @grey30;
@filter-item-color: @black;
@filter-item-bg--hover: @grey30;
@filter-item-check-bg--hover: @grey200;
@filter-clearlink-color: @grey200;
@filter-activeitem-color: @black;
@filter-activeitem-bg: @grey15;
@filter-activeitem-border-color: @grey200;
@filter-closebutton-color: @white;
@m-filter-item-bg: @grey30;
@m-filter-item-bg--active: @grey15;
@m-filter-title-bg: @grey300;

// Price slider
@priceslider-bg: @grey30;
@priceslider-range-bg: @primary;
@priceslider-handle-bg: @grey300;
@priceslider-handle-bg--hover: @grey200;

// Category overview
@category-color: @black;
@category-bg: @white;

// Cart
@cart-summary-bg: @grey15;
@cart-totals-border-color: @grey70;

// Owl
@owl-videowrapper-bg: @black;

// Photoswipe
@pswp-bg: @white;
@pswp-message-color: @grey300;

// Checkout
@checkout-page-bg: @white;
@checkout-logo-bg: @primary;
@checkout-step-icon-color: @grey200;
@checkout-step-title-border-color: @grey50;
@checkout-step-content-bg: @grey15;
@checkout-step-content-color: @grey300;
@checkout-review-table-border: @grey50;
@checkout-selected-address-border-color: @grey70;
