.aw-content.team {
  margin:0 -.5rem 0 -.5rem;
}
.aw-content.team h2 {
  color: @primary;
  margin: .5em 0 .2em;
}
.aw-content.team img {
  width:100%;
  max-width:100%;
  height:auto;
}
