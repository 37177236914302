@import '_filters.less';
@import '_links.less';

.subcategories {
  &__title {
    border-bottom: 1px solid @green-light;
  }
}

.row-product-tiles .product-item__image {
  background-color: @white;
}
