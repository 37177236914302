.usps {
  padding: 0;
  margin: 0;

  .usp {
    text-align: center;
    list-style: none;
    font-size: 1.1rem;
    font-weight: 600;
    color: @black;
    margin: 0;
    padding: 0;

    @media (max-width: @breakpoint-lg) {
      padding: 0.5rem 0;
    }

    @media (max-width: @breakpoint-s) {
      text-align: left;
    }

    &__link {
      display: inline-block;
      font-weight: 600;
      color: inherit;
      opacity: 1;
      transition: opacity 0.2s ease;

      &:hover {
        text-decoration: none;
        opacity: 0.6;
      }
    }

    svg {
      position: relative;
      top: 0.15em;
      display: inline-block;
      fill: @primary;
      height: 1.2rem;
      width: 1.2rem;
      margin-right: 0.5em;
    }
  }
}
