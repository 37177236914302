.experience.two-column {
  font-size: 1.1rem;

  table {
    border-style: none;

    td,
    th {
      border: initial;
      padding: 10px 0;

      &:first-child {
        width: 20%;

        @media (max-width: @breakpoint-md) {
          width: 50%;
        }
      }
    }
  }
}
