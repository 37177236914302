.sooqrSearchContainer[class][class][class][class][class][class][class][class] {
  margin-top: 3.5rem;
  box-shadow: unset;
  border-radius: 0;
  border: 1px solid @grey100;

  @media (min-width: @breakpoint-md) {
    .sqr-button {
      .button--primary();
      font-size: 1rem;

      &:link {
        color: @white;
      }
    }
  }

  .searchFilterField {
    label {
      padding-left: 2rem;
      height: 21px;
    }
  }

  .sqr-powered-by-branding {
    display: none;
  }

  .ui-slider-range,
  .sooqrSearchMobileTabs {
    background: @primary;
  }

  .sooqrCustom[class][class][class] .sqr-content.sqr-no-results {
    h1 {
      color: @primary;
    }

    a.sqr-back-button {
      color: @white;
      background-color: @primary;
    }
  }
}

.sooqrSearchContainer {
  height: 100vh;
  .sooqrSearchResultsContainer {
    height: 100%;
    overflow-y: auto;

    .sqr-results .sqr-resultItem .sqr-text[class][class][class][class][class] h3 a{
      color: @primary;
    }

    .sqr-options {
      position: unset;

      &.sqr-fixed[class][class][class] {
        position: absolute;
      }
    }
  }
}