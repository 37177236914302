.customer-account-login {
  .aw-login-message {
    background-color: @grey;
    color: @primary;
    font-size: 1rem;

    svg {
      max-width: 25px;
      width: 25px;
      fill: @primary;
    }
  }
}
