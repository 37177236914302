.footer-top {
  margin-top: 6rem;

  @media (max-width: @breakpoint-xl) {
    margin-top: 1rem;
  }

  .employee {
    width: 210px;
    position: relative;

    img {
      position: absolute;
      bottom: 0;
    }

    @media (max-width: @breakpoint-md) {
      position: absolute;
      right: -60px;
      bottom: 0;
    }
  }

  .tagline {
    font-family: @font-branded;
    text-transform: uppercase;
    font-size: 1.8rem;

    .line-2 {
      color: @primary;
    }
  }
}

.footer-usps {
  list-style: none;
  padding: 0;
  margin: 0;

  .footer-usp {
    margin: 0;

    a {
      opacity: 1;
      display: block;

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }

    &__icon svg {
      width: 45px;
      height: 45px;
    }

    .title {
      font-weight: 600;
    }
  }
}

.aw-footer .footer-usps {
  flex-direction: column;

  @media (min-width: @breakpoint-xl) {
    flex-direction: row;
  }
}

.footer-middle {
  background-color: @footer-mid-bg;
  color: @footer-mid-color;

  .footer-socials {
    list-style: none;

    svg {
      width: 30px;
      height: 30px;

      @media (max-width: @breakpoint-sm) {
        width: 35px;
      }
    }
  }
}

.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;

  &__title {
    text-transform: uppercase;
    font-family: @font-branded;
    font-size: 1.5rem;
    margin-bottom: 15px;
    display: block;
  }

  &__subtitle {
    text-transform: uppercase;
    font-family: @font-branded;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  a {
    color: @footer-mid-color;

    svg {
      height: 22px;
      width: 7px;
      margin-right: 5px;
      vertical-align: -2px;
      fill: @footer-mid-color;

      @media (min-width: @breakpoint-sm) {
        display: none;
      }
    }
  }

  &__item {
    @media (max-width: @breakpoint-sm) {
      a {
        border-bottom: 1px solid lighten(@component-border-bottom-color, 10%);
        font-size: 1.2rem;
        display: flex;
        padding: 10px 0;
        font-weight: 600;
      }

      svg {
        margin-left: auto;
      }
    }
  }

  li {
    margin: 0 0 5px;
    color: @footer-mid-color;
    position: relative;
  }

  td {
    padding: 0 10px 5px 0;
  }
}

.footer-bottom {
  color: @grey200;

  .footer-payment-methods {
    justify-content: center;
  }

  .footer-certifications {
    border-top: 1px solid @grey200;

    img {
      max-height: 250px;
      width: auto;
    }
  }

  .footer-bottom-menu {
    border-top: 1px solid @grey200;

    a {
      color: @grey200;
    }

    ul {
      margin: 2rem 0 0.5rem;
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: center;

      li {
        padding: 0 10px;
      }

      @media (max-width: @breakpoint-md) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.footer-payment-methods {
  list-style: none;

  svg {
    width: 50px;

    @media (max-width: @breakpoint-sm) {
      width: 35px;
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}
