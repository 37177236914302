.aw-slidernav {
  &__button {
    border-radius: 5px;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border: 1px solid @green-light;
    background-color: @white;

    svg {
      fill: @primary;
      height: 10px;
      width: 10px;
    }

    &.prev svg {
      transform: rotate(180deg);
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &__progress {
    position: relative;
    height: 2px;
    background: @grey;
    width: 100%;
  }

  &__progressbar {
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    background: @primary;
    transition: 0.2s ease;
  }
}
