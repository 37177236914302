.help-icon {
  vertical-align: middle;

  svg {
    width: 18px;
    fill: @grey50;
  }

  &:hover {
    cursor:pointer;
  }
}

.modal-minimal .modal-inner-wrap {
    width: fit-content;

    > .modal-content > div {
        text-align: center;
    }
}


.modal-image {
  width: 80%;
  max-width: 570px;

  @media (min-width: @breakpoint-md) and (max-width: @breakpoint-xl) {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: (@breakpoint-md - 1)) {
    width: 100%;
    max-width: 250px;
  }
}

.modal-image:not(:nth-of-type(1)) {
    margin-top: 1rem;
}
