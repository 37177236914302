.input,
input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"],
select,
textarea {
  box-sizing: border-box;
  display: block;
  font-style: italic;
  height: auto;
  font-family: @font-body;
  color: @text-body-color;
  font-weight: @base-type-weight;
  line-height: 1.5rem;
  padding: 0.7em 1em;
  border: 1px solid @form-input-border-color;
  border-radius: 3px;
  box-shadow: none; // reset luma

  &:not([disabled]):focus {
    box-shadow: none; // reset Luma
    border-color: @form-input-border-color-focus;
  }

  &:disabled {
    border: 1px solid @form-input-border-color-disabled;
  }

  &.mage-error {
    border: 1px solid @form-input-error-color;
  }
}

.fieldset > .field > .label,
.fieldset > .fields > .field > .label,
.fieldset > .field.choice .label,
.fieldset > .fields > .field.choice .label {
  font-weight: 400; //reset luma
}

select,
.aw-select.aw-select { // specificity hack
  background-color: @form-select-bg-color;
  border: 1px solid @form-select-border-color; // reset browser default/luma
  padding: 0.9em 1em;

  &:focus {
    background-color: @form-select-focus-bg;
    outline: 1px solid @form-select-focus-outline-color;
    box-shadow: none; // reset browser default
  }
}

input[type="checkbox"],
input[type="radio"],
input[type="checkbox"][class][class],
input[type="radio"][class][class],
.input-checkbox,
.input-radio {
  position: absolute;
  visibility: hidden;

  & + .label,
  & + label:not([class*='rating']),
  & + a[class^="am-filter-item"] {
    position: relative;
    padding-left: 2.6rem;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      box-sizing: border-box;
      pointer-events: none;
    }

    &:before {
      top: 0;
      left: 0px;
      height: 1.5rem;
      width: 1.5rem;
      border: 1px solid @form-radio-border-color;
      z-index: 1;
    }

    &:after {
      top: 1px;
      left: 1px;
      margin: 0;
      width: ~"calc(1.5rem - 2px)";
      height: ~"calc(1.5rem - 2px)";
      z-index: 2;
      background-color: @white;
      mask-image:~"url('../Adwise_Icons/icons/check.svg')";
      background-size:95% 95%;
      background-position:center center;
      background-repeat:no-repeat;
      transform: scale(0);
      transition: transform 0.1s ease;
    }

    &:hover {
      cursor:pointer;

      &:before {
        border-color: @grey200;
      }
    }
  }

  & + .label:focus,
  &:checked + .label,
  & + label:focus,
  &:checked + label,
  &:checked + a[class^="am-filter-item"] {
    &:before {
      background: @form-radio-check-color;
      border-color: @form-radio-check-color;
    }
    &:after {
      transform: scale(0.7);
    }
  }
}

input[type="radio"] + .label,
input[type="radio"] + label {
  &:before,
  &:after {
    border-radius: 50%; // Not in style guide, but radio buttons should be round.
  }
}

.fieldset > .field.required > .label::after,
.fieldset > .fields > .field.required > .label::after,
.fieldset > .field._required > .label::after,
.fieldset > .fields > .field._required > .label::after {
  font-size: 1rem;
  vertical-align: middle;
}

//Change Luma error state validation message
.field-error,
div.mage-error[generated] { // override Luma specificity
  color: @form-input-error-color;
  white-space: normal; // Override Luma
  font-size: 0.9rem;
}

.form-field {
  margin-bottom: 0.5rem;

  label {
    display: inline-block;
    font-weight: 600;
    font-size:1.1rem;
    margin: 0.5rem 0;
  }
}
