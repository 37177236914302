@import '_photoswipe-vendor';
@import '_owl-vendor';

.aw-product-info-chars-labels {
  .unstyle-list();
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  width: 50px;

  @media (min-width: @breakpoint-md) {
    width: 60px;
  }
}

.aw-product-info-chars-labels {
  li {
    margin-bottom: 5px;
    position: relative;

    &[data-desc]::after {
      position: absolute;
      right: 100%;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);
      width: 240px;
      padding: 5px 10px;
      background-color: @base-type-color;
      color: @base-page-bg-color;
      font-size: .9em;
    }

    &[data-desc]:hover::after {
      content: attr(data-desc);
    }
  }

  // 1. Make text small and fit inside the image in case of a 404
  img {
    font-size: 12px; // [1]
    word-break: break-all; // [1]
    display: block;
  }
}

.aw-gallery {
  &__container {
    position: relative;
  }

  &__wrap {
    display: flex;
  }

  &__main {
    background: @grey10;
    border: 1px solid @grey30;
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      border-color: @primary;
    }
  }

  &__mainimage {
    display: block;
    align-self: center;
    margin: auto;

    img {
      display: block;
      margin: auto;
    }
  }

  &__thumbs {
    height: 100%;
    margin: 5px -5px 0 -5px;
    padding: 0;
  }

  &__thumbwrap {
    padding: 5px;
    width: calc(100% / 7);

    &--hidden {
      display: none;
    }
  }

  &__thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    align-self: center;
    background: @grey10;
    border: 1px solid @component-border-color;
    transition: border-color 0.1s ease;
    border-radius: 3px;

    @media (max-width: @breakpoint-s) {
      font-size: 1rem;
    }

    &:hover {
      border-color: @primary;
    }

    img {
      display: block;
      padding: 10%;
      height: auto;
    }
  }

  &__extraimages {
    position: absolute;
    border-radius: 50%;
    background: @primary;
    color: @white;
    text-align: center;
    line-height: 3em;
    font-weight: 600;
    font-size: 1em;
    top: 50%;
    left: 50%;
    height: 3em;
    width: 3em;
    margin-left: -1.5em;
    margin-top: -1.5em;

    @media (max-width: @breakpoint-md) {
      font-size: 0.9em;
    }
  }
}

.pswp {
  &__ui {
    position: relative;
    z-index: 99;
  }

  &__counter {
    float: left;
  }

  &__top-bar {
    text-align: right;
    padding: 1rem;
  }

  &__button {
    .button();

    svg {
      pointer-events: none;
      margin-left: 0;
    }

    &--close {
      svg {
        position: relative;
        top: 0.1em;
        height: 0.8em;
      }
    }

    &--zoom {
      margin-right: 1rem;

      @media (max-width: @breakpoint-md) {
        display: none;
      }

      span {
        pointer-events: none;
      }

      svg {
        position: relative;
        top: 0.1em;
        height: 1em;
      }

      .zoom-out {
        display: none;

        .pswp--zoomed-in & {
          display: block;
        }
      }

      .zoom-in {
        display: block;

        .pswp--zoomed-in & {
          display: none;
        }
      }

    }

    &--arrow {
      &--right,
      &--left {
        position: absolute;
        top: 50vh;
        height: 4rem;
        width: 4rem;
        text-align: center;

        svg {
          height: 100%;
          width: 100%;
        }
      }

      &--left {
        left: 5%;

        svg {
          transform: rotate(180deg);
        }
      }

      &--right {
        right: 5%;
      }
    }
  }

  .pswp__caption.pswp__caption--fake {
    display: none;
  }

  .pswp__caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 80vh;
    display: flex;

    .pswp__caption__center {
      margin: 0 auto;
    }

    .pswp__caption__center .pswp__caption-inner {
      font-size: 1.15rem;
      text-align: center;
      background: @primary;
      color: @white;
      padding: 20px;
      border-bottom-right-radius: 15px;
      max-width: 75vw;
    }
  }
}

.aw-popupgallery {
  position: absolute;
  bottom: 10px;
  width: 100%;

  .owl-item {
    cursor: pointer;
    background: @grey10;
    border: 1px solid @component-border-color;
    border-radius: 3px;
    transition: border-color 0.1s ease;

    &:hover,
    &.center {
      border: 1px solid @primary;
    }
  }

  &__slide {
    padding: 15px;
  }
}
