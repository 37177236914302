.filter-options-item {
  border-bottom-color: @green-light;
}

#am-shopby-container {
  .am-shopby-item[class] {
    border-color: @green-light;
  }
}

.filter-current-subtitle {
  font-size: 1.5rem;
  text-transform: none;
}

.filter-current {
  .aw-clearall-link {
    color: @primary;
  }
}

.amshopby-slider-container[class] {
  .am-slider {
    .ui-slider-horizontal {
      position: relative;
      background: @priceslider-bg;
      margin: 1rem 0;
    }

    .ui-slider-range {
      position: relative;
      height: 6px;
      background: @priceslider-range-bg;
    }

    .ui-slider-handle {
      background: @white;
      border: 2px solid @primary;

      &:hover {
        background: @grey15;
      }
    }
  }
}

.tooltip.amshopby-filter-tooltip {
  opacity: 1;
}

div.ui-tooltip.ui-widget {
  padding: 15px;
  line-height: 1.6em;
}