.aw-variants {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
.aw-variant {
  padding: 0.5rem;
  background: @grey;
  font-weight: 600;
  text-align: center;
  border-bottom: 4px solid transparent;
  transition: all 0.2s ease;

  &:hover,
  &.active {
    text-decoration: none;
    border-bottom-color: @primary;
  }
}
