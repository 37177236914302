.aw-home {
  &__content {
    h1 {
      margin: 0 0 1.5rem 0;
      font-size: 1.5rem;
      font-family: @font-body;
      font-weight: bold;
    }
  }
}
