.label-widget {
  img {
    height: 4em;
  }

  b {
    margin-bottom: 0.2em;
  }

  a {
    color: @primary;

    &::before {
      content: "\203A";
      font-weight: 800;
      font-size: 1.4em;
      padding-right: 3px;
    }

    &:hover {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }
}
