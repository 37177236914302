.aw-accordion {
  .accordion-item {
    &__link {
      border-top: 1px solid @green-light;

      &:after {
        background-color: @primary;
      }

      &:not(.collapsed) {
        border-bottom: 1px solid @green-light;
      }
    }
    &__content {
      a {
        color: @primary;
        text-decoration: underline;
      }
    }
  }
}
