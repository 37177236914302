// CSS image replacement
#text-hide(@ignore-warning) {
	font: ~"0/0 a";
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;

	& when not (@ignore-warning) {
		// @warn "The `text-hide()` mixin has been deprecated as of v4.1.0. It will be removed entirely in v5.";
	}
}
