.configurator-title {
  font-family: @font-branded;
  text-transform:uppercase;
  border-bottom: 1px solid @green-light;
  padding-bottom:25px;
  margin-bottom:35px;
  display:block;
  font-size:1.6rem;
}

.vdt-configurator {
  .radio-image {
    width: 80px;
    height: 80px;
  }

  .radio-label {
    font-size:0.9rem;
  }

  a {
    color: @green;
    text-decoration: underline;

    a:hover {
      text-decoration: none;
    }
  }
}
