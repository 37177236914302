// Reset/undo Luma styles
.minicart-wrapper {
  float: none; // reset luma
  margin-top: 0; // reset luma
  margin-left: 0; // reset luma
  margin-right: 5px;

  .editcart {
    width: 100%;
    margin-top: 10px;
  }

  .action.showcart,
  .action.showcart.active {
    margin-left: 0; // reset luma
    margin-right: 0;

    &::before {
      content: ''
    }

    .counter.qty { // Extra specificity for Luma
      position: absolute;
      top: -0.75rem;
      right: 0;
      height: 1.5rem;
      line-height: 1.5rem;
      background-color: @minicart-qty-bg;
      font-size: .8em;
    }

    .counter-number {
      text-shadow: none; // reset Luma
    }

    svg {
      width: 22px;
      height: 22px;
      margin-bottom: 3px;
      fill: @primary;

      @media (max-width: (@tablet-max-width - 1px)) {
        margin-top: 10px;
        width: 32px;
        height: 32px;
      }
    }
  }

  .cart-label {
    .aw-customer-link__label();
  }

  .block-content {
    .subtitle.empty {
      padding: 30px 0;
    }
  }

  .block-minicart {
    padding: 1rem;
    margin-top: 0;
    right: 0;
    font-size: 1rem;
    border-width: 0 0 3px 0;
    border-color: @component-border-bottom-color;
    box-shadow: @dropshadow-2;
    z-index: 1000;

    @media (max-width: @tablet-max-width) {
      left: 0;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .minicart-items-wrapper {
    border-bottom: 0;
  }

  .minicart-items a, // specificity hack for Luma
  a {
    color: inherit;
  }

  .minicart-widgets {
    margin: 0;
  }

  .actions .secondary {
    padding: 0 10px;

    a.action.viewcart {
      .button--secondary();
      margin-bottom: 15px;
      width: 100%;
    }
  }
}

#btn-minicart-close {
  display: none;
}
