// Import Magento2
@import './styles-m.css';
@import './styles-l.css';

@import 'components/bootstrap/main.less';
@import '_settings.less';

@import 'components/_base.less';
@import 'components/_typography.less';
@import 'components/_button.less';
@import 'components/_form.less';
@import 'components/_price.less';
@import 'components/_usps.less';
@import 'components/_hero.less';
@import 'components/header/main.less';
@import 'components/footer/main.less';
@import 'components/_home.less';
@import 'components/category/main.less';
@import 'components/pdp/main.less';
@import 'components/checkout/main.less';
@import 'components/cart/main.less';
@import 'components/cart/minicart.less';
@import 'components/_page.less';
@import 'components/_modal.less';
@import 'components/_breadcrumbs.less';
@import 'components/_product_usp_list';
@import 'components/customer/main.less';
@import 'components/_notifications.less';
@import 'components/_producttiles.less';
@import 'components/_slider.less';
@import 'components/_datepicker.less';
@import 'components/quotation/main';
@import 'components/blog/main.less';
@import 'components/_feedbackcompany.less';
@import 'components/_postcode-nl.less';
@import 'components/cms/main.less';
@import 'components/_browser-update.less';

html {
  -webkit-text-size-adjust: none;
}

/* body */

body {
  background-color: @base-page-bg-color;
  overflow-x: hidden;
}

.container {
  @media (max-width: @breakpoint-xl) {
    max-width:none !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}
