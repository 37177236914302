@clippingInset: 7px;
@clippingPathStep: polygon(calc(100% - @clippingInset) 0, 100% 50%, calc(100% - @clippingInset) 100%, 0 100%, @clippingInset 50%, 0 0);
@clippingPathStepStart: polygon(calc(100% - @clippingInset) 0, 100% 50%, calc(100% - @clippingInset) 100%, 0 100%, 0 50%, 0 0);
@clippingPathStepEnd: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, @clippingInset 50%, 0 0);

.experience.instruction {
  border-top: 1px solid @green-light;
  border-bottom: 1px solid @green-light;
  position: relative;
  overflow: hidden;
  font-size: 1.1rem;

  .instruction-title {
    font-size: 3rem;
  }

  .instruction-bg {
    position: absolute;
    object-fit: contain;
    object-position: left top;
    height: 125%;
    top: 0;
    left: 0;
    opacity: 0.1;

    @media (max-width: @breakpoint-md) {
      object-position: left bottom;
      bottom: -10%;
    }
  }

  .instruction-navigation-step {
    position: relative;
    padding: 10px;
    font-size: 1rem;
    color: @grey300;
    cursor: pointer;

    .instruction-navigation-step-content {
      position: relative;
      z-index: 2;
    }

    .instruction-navigation-step-bg {
      background-color: @grey50;
      clip-path: @clippingPathStep;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &::before {
        content: '';
        display: block;
        background-color: @grey15;
        clip-path: @clippingPathStep;
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
      }
    }

    &:first-of-type .instruction-navigation-step-bg {
      clip-path: @clippingPathStepStart;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      &::before {
        clip-path: @clippingPathStepStart;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:last-of-type .instruction-navigation-step-bg {
      clip-path: @clippingPathStepEnd;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      &::before {
        clip-path: @clippingPathStepEnd;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &.current {
      color: @black-alt;
    }

    width: 33.33%;

    @media (max-width: @breakpoint-md) {
      transition: all 0.2s ease-in-out;
      width: 25%;

      &.current {
        width: 50%;
      }
    }
  }

  .instruction-navigation.steps-1 {
    .instruction-navigation-step {
      width: 100%;

      &.current {
        width: 100%;
      }

      @media (max-width: @breakpoint-md) {
        width: 100%;

        &.current {
          width: 50%;
        }
      }
    }
  }

  .instruction-navigation.steps-2 {
    .instruction-navigation-step {
      width: 50%;

      &.current {
        width: 50%;
      }

      @media (max-width: @breakpoint-md) {
        width: 33.33%;

        &.current {
          width: 66.66%;
        }
      }
    }
  }

  .instruction-container {
    .owl-stage {
      display: flex;
    }
  }

  .instruction-item {
    padding: 0 2px;

    h3,h4,h5,h6 {
      font-size: 1.45rem;
    }
  }
}
