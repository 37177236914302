.blog-header-wrapper {
    position:relative;
    background:@primary;
    color:@white;
    margin-top:-15px;
    padding-top:6rem;
    margin-left: calc(-100vw / 2 + 1240px / 2);
    margin-right: calc(-100vw / 2 + 1240px / 2);

    @media (max-width:1240px) {
        margin-left:0;
        margin-right:0;
    }

    .blog-header-inner {
        max-width:1080px;
        padding:15px 15px 50px 15px;
        margin:auto;
    }

    .post-view-header-content {
        text-transform:uppercase;
        .page-title-wrapper {
            max-width:460px;
            h1 {
                color:@white;
                margin-top:1.25rem;
            }
        }

        .post-view-header-small-text {
            font-size:0.875rem;
        }

        .post-view-header-meta {
            img {
                vertical-align:middle;
            }
            span {
                padding:3px 8px;
            }
            span, a {
                color:@white;
                font-weight:600;
                font-size:0.875rem;
                text-decoration:none;
            }
        }
    }

    .post-view-image {
        position:relative;
        float:right;
        margin-bottom:-6.5rem;
        z-index:2;
        img {
            box-shadow:@dropshadow-1;
        }
    }

    &:after {
        content:"";
        display:block;
        clear:both;
    }

    @media (max-width:@breakpoint-md) {
        padding-top:7rem;
        padding-bottom:1.5rem;
        .page-title-wrapper {
            max-width:none;
        }

        .post-view-image {
            display:none;
        }

        .blog-header-inner {
            &:after {
                width:110%;
                right:-10%;
            }
        }
    }
}
