.experience.video {
  .embed-responsive {
    margin-top: 1em;
    border-radius: 3px;
  }

  .experience-video-title {
    text-transform: uppercase;
    border-bottom: 1px solid #c5e0da
  }
}
