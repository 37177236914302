//@mixin transition($transition...) {
//  @if $enable-transitions {
//    @if length($transition) == 0 {
//      transition: $transition-base;
//    } @else {
//      transition: $transition;
//    }
//  }
//}
#transition(@transition...) when (@enable-transitions) {
	& when (length(@transition) = 0) {
		transition: @transition-base;
	}
	// LESS PORT: In order to output the transitions correctly we have to iterate over the list and
	// use Less’s merge feature. Without this, transitions will be output space-separated instead of
	// comma-separated. Also, since a single transition can be misinterpreted as multiple transitions
	// (since it will have a length > 1) we have to include a check for the length of the first item
	// in the list. If the length is greater than 1, then we have a list of separate transitions. If
	// the the length is 1, then we’re looking at the first value of a single transition, so we
	// output `@transition` as-is.
	& when (length(@transition) > 0) and (length(extract(@transition, 1)) = 1) {
		transition: @transition;
	}
	& when (length(@transition) > 0) and (length(extract(@transition, 1)) > 1) {
		#each-transition(@i: 1) when (@i =< length(@transition)) {
			transition+: extract(@transition, @i);

			#each-transition((@i + 1));
		} #each-transition();
	}

	@media screen and (prefers-reduced-motion: reduce) {
		transition: none;
	}
}
