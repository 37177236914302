.experience.carousel-content {
  .carousel-container {
    position: relative;
    padding-bottom: 60%;
    background-color: @grey15;

    .owl-carousel {
      position: absolute;
      top: 0;
    }

    .carousel-item {
      padding-bottom: 60%;
      position: relative;

      img {
        position: absolute;
        object-fit: cover;
      }
    }

    .carousel-indicator {
      color: @white;
      position: absolute;
      z-index: 2;
      left: 30px;
      bottom: 30px;
      height: 30px;

      .carousel-index {
        font-weight: 700;
      }

      .carousel-separator {
        width: 1px;
        background-color: @white;
      }
    }

    .carousel-navigation {
      position: absolute;
      z-index: 2;
      right: 30px;
      bottom: 30px;

      .carousel-navigation-button {
        border-radius: 5px;
        width: 44px;
        height: 44px;
        cursor: pointer;
        background-color: @green;

        svg {
          fill: @white;
          height: 10px;
          width: 10px;
        }

        &.prev svg {
          transform: rotate(180deg);
        }
      }
    }

    @media (max-width: @breakpoint-md) {
      margin-bottom: 64px;

      .carousel-indicator {
        left: 0;
        bottom: -44px;
        color: @black-alt;

        .carousel-separator {
          background-color: @green-light;
        }
      }

      .carousel-navigation {
        bottom: -54px;
        right: 0;

        .carousel-navigation-button {
          border: 1px solid @green-light;
          background-color: @white;

          svg {
            fill: @primary;
          }
        }
      }
    }
  }

  .col-md-4 {
    .carousel-container {
      padding-bottom: 125%;

      .carousel-item {
        padding-bottom: 125%;
      }
    }
  }

  .carousel-content {
    font-size: 1.1rem;

    ul li {
      margin-left: 20px;
      margin-bottom: 10px;

      &:before {
        transform: scale(1);
        top: 0.15rem;
      }
    }

    hr {
      border-color: @green-light;
    }
  }
}
