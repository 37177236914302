.product-options-wrapper[class] {
  .label {
    font-size:1.1rem;

    &::after {
      display:none;
    }
  }

  .field .control {
    width: 100%;
  }
}
