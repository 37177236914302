.aw-accordion {
  .accordion-item {
    &__link {
      position:relative;
      display:block;
      padding: 25px 0;
      border-top: 1px solid @grey30;
      cursor:pointer;
      font-size: 1.1rem;
      font-weight:600;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:after {
        content:'';
        width:7px;
        height:11px;
        display:block;
        z-index: 2;
        background-color: @grey70;
        mask-image: ~"url('../Adwise_Icons/icons/chevron.svg')";
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        transition: transform 0.1s ease;
        transform: scale(1.75) rotate(90deg);
        position:absolute;
        right:20px;
        top:28px;
      }

      &:not(.collapsed) {
        border-bottom: 1px solid @grey30;

        &:after {
          transform: scale(1.75) rotate(-90deg);
        }
      }
    }

    &__content {
      padding: 25px 0;
    }
  }

  h2, h3, h4, h5, h6 {
    margin: 10px 0;
    font-size: 1.6rem;
    font-weight: 700;
  }

  table > * > tr {
    > th:first-child,
    > td:first-child {
      width: 40%;
    }

    th, td {
      padding: 5px;
    }
  }

  table.product-info-table > * > tr {
    th, td {
      width: auto;
      padding: 11px 10px;
    }

    td:last-child {
      white-space:nowrap;
    }
  }

  a[href*=".pdf"] {
    .button--secondary();
  }
}
