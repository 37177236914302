.aw-navigation {
  background: @navigation-bg;
  z-index: 4;
  position: relative;
  transition: all 0.1s ease-in-out;
  transition-delay: 0.3s;
  box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0);
}

.menu-shadow {
  box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.5);
}

@media (min-width: @tablet-max-width) {
  .aw-topmenu {
    position: relative;
    margin: 0;

    ol {
      margin: 0;
    }

    // There is a lot of nested lists and anchor tags going on,
    // so set some sensible defaults for them
    li {
      list-style: none;
    }

    a {
      display: block;
      text-decoration: none;
    }

    &__close {
      display: none;
    }

    &__menutitle {
      display: none;
    }

    // The horizontal list of topics
    &__topics {
      display: flex;
      gap: 1.5rem;
      flex-wrap: wrap;
      position: relative;
      padding-left: 0;
    }

    &__topic {
      display: block;
      margin: 0;

      // Link inside the horizontal list of topics
      &-link[class] {
        font-family: @font-body;
        color: @navigation-topic-color;
        position: relative;
        font-size: 1em;
        font-weight: 600;
        padding: 1em;
        margin-bottom: 0;
        border: 0;
        transition: border 0.2s ease;

        .aw-topmenu__topic--is-current-page &,
        .aw-topmenu__topic:hover &,
        &:focus {
          background-color: @white;
          color: @black;
          z-index: 1;
        }
      }
    }

    &__toggler {
      display: none;
    }

    // The dropdown that appears when you hover one of the topics
    &__dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: @white;
      z-index: 99;
      padding: 0 1em 1.5em 1em;
      opacity: 0;
      transition: 0s;
      transition-delay: 0s;
      pointer-events: none;

      &:before {
        background: @white;
        content: '';
        height: 100%;
        width: 150vw;
        position: absolute;
        top: 0;
        left: -50vw;
        z-index: -1;
        border-bottom: 2px solid @component-border-bottom-color;
      }

      .dropdown-close {
        position: absolute;
        right: 7rem;
        top: 1rem;
        margin: 0 0.5rem;
        cursor: pointer;
        color: @primary;
        z-index: 1;

        svg {
          width: 20px;
          height: 20px;
          fill: @primary;
          display: block;
        }
      }
    }

    // The dropdown is hidden by default, but shown if it is focussed/hovered
    // or when the parent topic is hovered
    .aw-topmenu__topic:hover,
    .aw-topmenu__topic-link:focus {
      .aw-topmenu__dropdown {
        opacity: 1;
        transition: opacity 0.1s ease-in-out;
        transition-delay: 0.3s;

        &.dropdown-transition-end {
          pointer-events: auto;
        }
      }
    }

    // The sections/columns within each dropdown
    &__sections {
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding-left: 0;
    }

    // A single section/column within a dropdown
    &__section {
      flex: 1 1 15em;
      margin: 0.5rem;
    }

    // The title of a section/column in a dropdown
    .aw-topmenu__title {
      display: block;
      font-family: @font-branded;
      color: @black;
      font-size: 1rem;
      border: 1px solid @grey30;
      margin: 0;
      transition: all 0.2s ease;

      &:hover,
      &:focus {
        background: @black;
        color: @white;
        text-decoration: none;
        border-color: @black;
      }

      &-label {
        padding: 1em;
      }
    }

    &__items {
      margin-top: 1.5rem;
      padding-left: 0;

      columns: 240px 2;
      column-gap: 6em;
    }

    &__item {
      margin-bottom: 0.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    // A link inside a section/column in a dropdown
    &__link {
      position: relative;
      display: block;
      color: @grey;
      font-size: 0.9em;
      max-width: 16em;
      max-width: -webkit-fill-available;
      padding: 0.15em 2em 0.15em 0;

      &:visited {
        color: @grey;
      }

      &-icon {
        position: absolute;
        top: 0.15em;
        right: 0.5em;
        transition: right 0.2s ease;

        svg {
          position: relative;
          top: 0.1em;
          margin-left: 1em;
          fill: @blue;
          height: 0.8em;
        }
      }

      .aw-topmenu__item--is-current-page &,
      &:hover,
      &:focus {
        color: @blue;
        text-decoration: none;

        .aw-topmenu__link-icon {
          right: 0em;
        }
      }
    }

    &__showall {
      display: block;
      padding: 0 0 0.8em;
      margin: 0.5em 0 0 0 !important;
      border-bottom: 1px solid @grey15;
      border-top: none;

      a {
        font-size: 12px;
      }

      &-item {
        margin-bottom: 0;
      }

      &-link[class] {
        display: inline-block;

        &:hover {
          .aw-topmenu__showall-icon svg {
            left: 0.5em;
          }
        }
      }

      &-icon {
        svg {
          position: relative;
          top: 0.1em;
          left: 0;
          margin-left: 1em;
          fill: @blue;
          height: 0.8em;
          transition: left 0.2s ease;
        }
      }
    }

    .dropdown-closed[class] {
      display: none;
    }
  }
}
