@import '_header';
@import '_layout';
@import '_tooltip';
@import '_addresses';
@import '_success';

.onestepcheckout-index-index {
  .osc-authentication-wrapper {
    display: none;
  }

  .create-account-block {
    display: none !important;
  }

  .step-title {
    border: none;

    .fa {
      display: none !important; // To override a vendor !important
    }
  }

  .checkout-agreement[class][class] {
    label[for="agreement__1"] {
      padding-left: 0;
    }

    button {
      color: @text-body-color;
      text-decoration: underline;
      margin-left: 40px;
      text-align: left;

      span {
        font-size: 1rem;
        font-weight: 400;
      }

      &:hover {
        color: @text-body-color;
        text-decoration: none;
      }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { /* IE10+ hack */
      label[for="agreement__1"] {
        padding-left: 20px;
      }

      button {
        margin-left: 10px;
        padding-right:30px;
      }
    }
  }

  .agreements-modal {
    .modal-header .action-close {
      display: none;
    }

    .modal-footer {
      border-color: @grey30;

      button.action-hide-popup {
        .button--primary();
      }
    }
  }

  .field.street {
    > .control {
      display: flex;

      > .field:first-child {
        width: 70%;
      }
    }

    .field.additional {
      margin-top: -29px;
      padding-left:15px;

      .control {
        margin-top: 5px;
      }

      .label {
        position: relative;
        font-weight: 400;
        display: inline-block;
        height: auto;
        width: auto;
        clip: initial;
        font-size: 1rem;

        &:after {
          content: '*';
          color: @text-body-color;
          font-size: 1.2rem;
          margin: 0 0 0 5px;
        }
      }
    }
  }
}

.checkout-onepage-success .checkout-success {
  padding:0 15px;
}

.onestepcheckout-index-index {
    .opc-wrapper .fieldset > .field > .label, .field .control.input-field .label {
        white-space: normal;
    }
}
