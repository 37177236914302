.action.showcart {
  &:before {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.minicart-wrapper .action.close {
  &:focus {
    outline:none;
  }

  &::before {
    content: ' ';
    width: 16px;
    height: 16px;
    display: block;
    background-image: ~"url('../images/close.svg')";
  }

  @media (max-width: @breakpoint-lg) {
    left: 10px;
    top: 10px;
  }
}

