.aw-tabs {
  .nav.nav-tabs {
    flex-wrap: nowrap;
    margin-bottom: 30px;

    .nav-item {
      margin: 0;
      display: flex;
      padding-right:20px;
    }

    .nav-link {
      font-size: 1rem;
      font-weight: 700;
      padding: 1rem 0;
      align-self: center;

      &.active,
      &:hover {
        border-color: transparent;
        color: @grey200;
      }
    }

    border-top: 1px solid @grey70;
    border-bottom: 1px solid @grey70;
  }

  .tab-content {
    line-height: 2;
  }

  h2, h3, h4, h5, h6 {
    margin: 10px 0;
    font-size: 1.6rem;
    font-weight: 700;
  }
}

.tab-content[class] {
  table > * > tr {
    > th:first-child,
    > td:first-child {
      width: 40%;
    }

    th, td {
      padding: 1px;
    }
  }

  table.product-info-table > * > tr {
    th, td {
      width: auto;
      padding: 11px 10px;
    }

    td:last-child {
      white-space:nowrap;
    }
  }

  a {
    color: @primary;
  }
  a[href*=".pdf"] {
    .button--secondary();
  }
}
