#ui-datepicker-div {
  background-color: @white;
  padding: 5px 5px 15px 5px;
  box-shadow: @box-shadow;
  display: none;

  .ui-datepicker-header {
    display: flex;
    flex-wrap: wrap;

    .ui-datepicker-prev,
    .ui-datepicker-next {
      padding: 5px;
      font-weight: 600;
      color: @primary;
      cursor: pointer;
    }

    .ui-datepicker-next {
      margin-left: auto;
    }

    .ui-datepicker-title {
      width: 100%;
      display: flex;

      .ui-datepicker-month {
        margin-right:3px;
      }

      .ui-datepicker-year {
        margin-left:3px;
      }
    }
  }

  .ui-datepicker-calendar {
    td {
      text-align:center;

      span {
        color: @grey200;
      }
    }

    td:not(.ui-datepicker-unselectable) {
      a {
        color: @primary;
        font-weight: 600;
      }
    }
  }
}
