.container.inspiration-page {
    .page-title {
        text-transform:uppercase;
        margin:1.5rem 0 2.5rem 0;
    }

    .moodboard-wrapper {
        margin-top:6rem;
        .section__title {
            border-bottom:1px solid @grey50;
            padding-bottom:1.75rem;
        }
        .eapps-instagram-feed-posts-grid-load-more {
            display:none;
        }
    }
}
