.account {
  .block-title {
    color: @black;
  }
  .page-title {
    text-transform: uppercase;
    font-size: 2.6rem;
  }

  .order-status {
    display: none;
  }

  .block-title {
    display: flex;
    align-items: center;

    strong {
      text-transform: uppercase;
      font-size: 1.8rem;
      font-family: @font-branded;
      margin-right: 10px;
    }
    a.action {
      color: @grey200;
      font-style:italic;
    }
  }

  .block-search .block-title {
    display: none;
  }

  .block-content {
    .box-title {
      padding-top:15px;
      display: block;
      font-size:1.1rem;
    }
    p {
      font-size:1rem;
    }
    address {
      font-size:1rem;
      font-style:italic;
    }
  }

  .table-wrapper {
    border-bottom: 0;

    .table {
      th {
        background-color: transparent;
        border-bottom: 0;

        &.status{
          text-transform: capitalize;
        }
      }

      td {
        border-bottom: 0;
      }
    }
  }

  .block-collapsible-nav[class] {
    position: static;
  }

  .block-collapsible-nav {
    .block-collapsible-nav-title {
      display: none;
    }

    .content {
      background-color: transparent;
      display: block;

      .nav.items {
        flex-direction: column;

        .nav.item {
          a {
            padding-left:0;

            &:hover {
              background-color: transparent;
              text-decoration:underline;
            }
          }

          &.current a > strong {
            padding-left: 15px;
          }

          &.current strong,
          &.current a {
            border-color: @grey100;
          }
        }
      }
    }
  }
}

.customer-account-forgotpassword {
  .column.main {
    padding-left:15px;
    padding-right:15px;
  }
  .page-title {
    text-transform: uppercase;
    font-size: 2.6rem;
  }
}

.amasty_company-role-index {
    .action.add-new.primary {
      color: @white;
    }

  #my-roles-table .col {
    width: auto
  }
}

.customer-account-edit,
.customer-address-form {
  .fieldset .legend {
    display: none;
  }
}

.customer-account-index {
  .block-dashboard-addresses .block-content {
    display: flex;

    > .box {
      width: 50%;
    }
  }

  .block-dashboard-orders {
    .block-title {
      margin-bottom:15px;
    }
    .orders-recent[class] {
      margin: 0;
    }
  }
}

.customer-address-index {
  .block-addresses-default .block-content {
    display: flex;

    > .box {
      width: 50%;
    }
  }
}

.substitute-order-index {
  .ui-datepicker-trigger {
    display: none;
  }

  .substitute-order-search {
    color: @black;

    .field.search {
      .label {
        font-size:1.8rem;
        text-transform: uppercase;
        font-family: @font-branded;
      }
    }

    .field.date input._has-datepicker {
      width: 100%;
    }

    .action {
      display: inline-block;
      padding-top:7px;
    }
  }
}

.substitute-order-view,
.substitute-order-invoice,
.substitute-order-shipment
{
  .action.print {
    display: none;
  }
  .items.order-links {
    margin-bottom: 0;

    .item a {
      line-height: unset;
    }
  }

  .table-order-items {
    .product-item-name {
      font-size:1.2rem;
    }
    .cart-price {
      font-size:1.2rem;
    }
  }
}

.customer-account-create {
  .page-main {
    padding-top: 30px;
  }

  form.create.account {
    margin-top: 25px;
    min-width: unset;
    width: 100%;
  }
}
