.catalog-product-view {
  .usps {
    background: transparent;
    border-top: 1px solid @grey50;
    border-bottom: 1px solid @grey50;
  }

  .product-usps {
    ul {
      margin-bottom: 0;
    }

    .product-impediments ul li {
      &::before {
        background-color: @grey70;
        border-radius: 50%;
        mask-image: unset;
      }

      &::after {
        content: '';
        position: absolute;
        mask-image: ~"url(../Adwise_Icons/icons/minus.svg)";
        background-color: @white;
        top: 0.1rem;
        left: -2.1rem;
        width: 18px;
        height: 18px;
        z-index: 4;
        mask-size: 65% 65%;
        mask-position: center;
        mask-repeat: no-repeat;
      }
    }
  }
}
