#aw-addtocart-notification {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  align-items: center;
}
.aw-cartnotification {
  margin: auto;
  background: @white;
  width: 420px;
  max-width: 90vw;
  border-bottom: 3px solid @primary;
  box-shadow: @dropshadow-2;
  z-index: 1000;


  @media (max-height: 920px) {
    &.sticky {
      position: fixed;
      top: 25px;
      right: 15px;
    }
  }

  .button {
    display: block;
    width: 50%;
    padding: 0.9em 1.5em;
  }

  .icon__check {
    align-self: center;
    height: 1.3em;
    position: relative;
    top: 1px;
    width: 1.3em;
    fill: @message-success-color;
    background-color: @message-success-bg;
    padding: .3em;
    margin-right: 4px;
  }
}

.aw-cartnotification__continue {
  color: @primary;
  cursor:pointer;

  &:hover {
    text-decoration: underline;
  }

  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: initial;
  }
}

.aw-cartnotification__message {

  & > div {
    background: @message-success-bg;
    color: @message-success-color;

    .accent {
      color: @message-success-color;
      font-weight: 600;
      margin-right: 0.25em;
    }
  }
}

.aw-cartnotification__product {
  align-items: center;
}

.aw-cartnotification__image {
  img {
    width: 55px;
    height: 55px;
    object-position: center;
    object-fit: contain;
  }
}

.aw-cartnotification__name,
.aw-cartnotification__product {
  font-weight: 600;
  font-size: 0.9rem;
}

.aw-cartnotification__name {
  padding-left: 8px;
  flex: 1;
}

.aw-cartnotification__price {
  text-align: right;
  font-size: 1.1rem;
}

#aw-cartnotification__specifications {
  margin-top: 5px;
  font-weight: 300;
  font-style: italic;

  span {
    display: block;
  }
}
