.aw-logo {
  display: block;
  width: 260px;

  .aw-logo__text {
    width:200px;
    margin-top:25px;
  }

  .aw-logo__animation {
    width:80px;
    margin-left:-10px;
  }

  @media (max-width: @breakpoint-md) {
    width:200px;
  }

  @media (max-width: @breakpoint-sm) {
    width:160px;
  }

  @media (max-width: @breakpoint-s) {
    flex-grow: 1;
    margin-left: 10px;

    .aw-logo__text {
      width: 150px;
    }

    .aw-logo__animation {
      width:60px;
    }
  }
}
