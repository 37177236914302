.product-info-price[class] {
  border-bottom: 1px solid @grey100;
  margin-bottom:1.5rem;
  width: unset !important;

  .price[class][class] {
    font-size: 2rem;
    font-weight:400;
    line-height: 26px;
  }

  .your-price {
    display: flex;
    flex-direction: column;

    .your-old-price {
      order: 2;

      .price-container {
        .price-label, .price {
          font-size: .95rem;
          color: @black;
          font-weight: 500;
          font-family: @font-body;
          line-height: 1;
          text-transform: lowercase;
        }
      }
    }

    .price-as-low-as {
      font-size: .95rem;
      text-transform: uppercase;
      color: #000;
      font-weight: 600;
      font-family: @font-body;
    }

    .your-special-price {
      order: 1;

      .price-container .price-label {
        font-size: .95rem;
        color: @black;
        font-weight: 600;
        font-family: @font-body;
        text-transform: lowercase;
      }

      .price-wrapper {
        margin-bottom: 0;
      }
    }

    .price-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      gap: 1rem;
      line-height: 1;
      width: fit-content;

      .price-label {
        margin-bottom: 0;
      }
    }
  }
}

.aw-pdp-price {
    display: flex;
}
.page-product-grouped .aw-pdp-price {
    display: none;
}

