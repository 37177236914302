.experience.gallery {
  .experience-gallery-thumbnails {
    width: 11%;
    position: relative;
  }

  .experience-gallery-thumbnail {
    &.hidden {
      display: none;
    }

    @media (max-width: @breakpoint-sm) {
      max-width: 25%;
    }
  }

  img {
    object-fit: cover;
    border-radius: 3px;
    cursor: pointer;

    @media (max-width: @breakpoint-sm) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .thumbnail-gradient {
    background-image: linear-gradient(transparent 25%, @white);
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform-origin: center center;
    pointer-events: none;

    @media (max-width: @breakpoint-sm) {
      transform: rotate(-90deg);
    }
  }

  .remaining-count-container {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .remaining-count {
    width: 32px;
    height: 32px;
    background-color: @green;
    color: @white;
    border-radius: 50%;
  }

  .experience-gallery-main {
    width: 89%;

    .experience-gallery-main-caption {
      border-bottom-right-radius: 25px;
      position: absolute;
      z-index: 2;
      right: 30px;
      bottom: 30px;
      pointer-events: none;
      max-width: 200px;
      padding: 25px 15px;
    }
  }

  .experience-gallery-main-caption {
    background-color: @green;
    color: @white;
    font-size: 1.1rem;
    padding: 15px 15px;
  }

  @media (max-width: @breakpoint-sm) {
    .experience-gallery-main,
    .experience-gallery-thumbnails {
      width: 100%;
    }
  }
}
