// Margin and Padding

//@each $breakpoint in map-keys($grid-breakpoints) {
//  @include media-breakpoint-up($breakpoint) {
//    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//
//    @each $prop, $abbrev in (margin: m, padding: p) {
//      @each $size, $length in $spacers {
//
//        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
//        .#{$abbrev}t#{$infix}-#{$size},
//        .#{$abbrev}y#{$infix}-#{$size} {
//          #{$prop}-top: $length !important;
//        }
//        .#{$abbrev}r#{$infix}-#{$size},
//        .#{$abbrev}x#{$infix}-#{$size} {
//          #{$prop}-right: $length !important;
//        }
//        .#{$abbrev}b#{$infix}-#{$size},
//        .#{$abbrev}y#{$infix}-#{$size} {
//          #{$prop}-bottom: $length !important;
//        }
//        .#{$abbrev}l#{$infix}-#{$size},
//        .#{$abbrev}x#{$infix}-#{$size} {
//          #{$prop}-left: $length !important;
//        }
//      }
//    }
//
//    // Some special margin utils
//    .m#{$infix}-auto { margin: auto !important; }
//    .mt#{$infix}-auto,
//    .my#{$infix}-auto {
//      margin-top: auto !important;
//    }
//    .mr#{$infix}-auto,
//    .mx#{$infix}-auto {
//      margin-right: auto !important;
//    }
//    .mb#{$infix}-auto,
//    .my#{$infix}-auto {
//      margin-bottom: auto !important;
//    }
//    .ml#{$infix}-auto,
//    .mx#{$infix}-auto {
//      margin-left: auto !important;
//    }
//  }
//}
#each-breakpoint-spacing(@i: 1) when (@i =< length(@grid-breakpoints)) {
	@breakpoint: extract(extract(@grid-breakpoints, @i), 1);

	#media-breakpoint-up(@breakpoint, {
		@infix: breakpoint-infix(@breakpoint, @grid-breakpoints);

		@spacing-properties: margin m, padding p;

		#each-spacing-property(@ii: 1) when (@ii =< length(@spacing-properties)) {
			@spacing-property: extract(@spacing-properties, @ii);
			@prop: extract(@spacing-property, 1);
			@abbrev: extract(@spacing-property, 2);

			#each-spacer(@iii: 1) when (@iii =< length(@spacers)) {
				@item: extract(@spacers, @iii);
				@size: extract(@item, 1);
				@length: extract(@item, 2);

				.@{abbrev}@{infix}-@{size} { @{prop}: @length !important; }
				.@{abbrev}t@{infix}-@{size},
				.@{abbrev}y@{infix}-@{size} {
					@{prop}-top: @length !important;
				}
				.@{abbrev}r@{infix}-@{size},
				.@{abbrev}x@{infix}-@{size} {
					@{prop}-right: @length !important;
				}
				.@{abbrev}b@{infix}-@{size},
				.@{abbrev}y@{infix}-@{size} {
					@{prop}-bottom: @length !important;
				}
				.@{abbrev}l@{infix}-@{size},
				.@{abbrev}x@{infix}-@{size} {
					@{prop}-left: @length !important;
				}

				#each-spacer((@iii + 1));
			} #each-spacer();

			#each-spacing-property((@ii + 1));
		} #each-spacing-property();

		// Some special margin utils
		.m@{infix}-auto { margin: auto !important; }
		.mt@{infix}-auto,
		.my@{infix}-auto {
			margin-top: auto !important;
		}
		.mr@{infix}-auto,
		.mx@{infix}-auto {
			margin-right: auto !important;
		}
		.mb@{infix}-auto,
		.my@{infix}-auto {
			margin-bottom: auto !important;
		}
		.ml@{infix}-auto,
		.mx@{infix}-auto {
			margin-left: auto !important;
		}
	});

	#each-breakpoint-spacing((@i + 1));
} #each-breakpoint-spacing();
