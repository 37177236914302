.linked-products-container {
  .label {
    font-size:1.1rem;
  }

  .slider-buttons {
    .slider-button {
      fill: @grey300;
      transition: opacity 300ms;
      cursor: pointer;

      svg {
        width: 10px;
        height: 14px;
      }

      &.disabled {
        opacity: 0.5;
        cursor: auto;
      }

      &.prev {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.linked-products {
  list-style: none;
  padding-left: 0;

  .linked-product {
    &.current {
      font-weight:600;

      > img {
        border: 1px solid @grey100;
      }
    }
  }
}

.tooltip {
  .tooltip-inner {
    img {
      margin-top: 3px;
      width: 200px;
      height: 200px;
    }

    span {
      display: block;
    }
  }
}
