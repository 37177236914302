.price-box.price-tier_price {
  display: block;
}

ul.prices-tier-header {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 3.5em;

  li {
    min-height: 3rem;

    & > p {
      margin: 0;
    }

    &::before {
      content: "";
      position: absolute;
      margin-top: .25em;
      margin-left: -3.5em;
      width: 2.5em;
      height: 2.5em;
      background-color: @black-alt;
      mask-image: ~"url(../Adwise_Icons/icons/forklift.svg)";
      mask-size: 100%;
    }
  }
}

.prices-tier {
  border: solid 1px @grey30;
  border-radius: .5rem;

  .item {
    font-weight: 300;
    font-size: 0.9rem;

    .price-container .price {
      font-size: 1rem;
    }

    .discount {
      color: @green;
      font-weight: 700;
    }
  }
}
