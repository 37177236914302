.checkout-onepage-success {
  .column.main {
    padding-bottom: 20px;
  }
}
.aw-success-page {
  .aw-hero__content--bg {
    opacity: 0.9;

    @media (max-width: @breakpoint-md) {
      display: block;
    }
  }
  .aw-hero__content {
    left: 0;
    width: 40%;

    @media (max-width: @breakpoint-lg) {
      width: 60%;
    }

    @media (max-width: @breakpoint-md) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    p {
      z-index: 1;
    }
  }
  .aw-hero__image {
    p {
      margin: 0;
      height: 100%;
    }

    img {
      border-radius: 3px;
    }
  }

  .aw-hero__button {
    color: @white;

    &:hover {
      color: @white;
    }
  }

  .order-block {
    border: 1px solid @grey50;
    font-size: 1.05rem;
    line-height: 1.5;
    border-radius: 3px;

    p {
      margin: 0;
    }

    svg {
      min-width: 45px;
      max-height: 45px;
    }
  }

  .success-menu {
    .success-menu__title {
      font-weight: 600;
      margin-bottom: 15px;
      font-size: 1.15rem;
      display: block;
    }

    .footer-usps {
      flex-direction: column;

      .footer-usp {
        margin-bottom: 10px;
      }
    }
  }

  .section__title {
    border-bottom: 1px solid @grey30;
  }

  #registration {
    margin: 0 15px;
  }

  @media (max-width: @breakpoint-md) {
    .aw-maps-directions {
      width: 100%;
    }
  }
}

