// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container when (@enable-grid-classes) {
	#make-container();
	#make-container-max-widths();
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

.container-fluid when (@enable-grid-classes) {
	#make-container();
}

// Row
//
// Rows contain and clear the floats of your columns.

.row when (@enable-grid-classes) {
	#make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters when (@enable-grid-classes) {
	margin-right: 0;
	margin-left: 0;

	> .col,
	> [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}

// Columns
//
// Common styles for small and large grid columns

#output-columns() when (@enable-grid-classes) {
	#make-grid-columns();
} #output-columns();
