.onestepcheckout-index-index {
  .shipping-address-items {
    padding: 0 15px;

    .shipping-address-item {
      border: 1px solid @grey30;

      button.edit-address-link {
        color: @grey100;
        font-weight: 600;
        text-decoration: underline;
        right: 14px;
      }

      &.selected-item {
        border: 2px solid @grey100;

        &::after {
          display: none;
        }
      }
    }
  }

  .checkout-shipping-address {

    .fieldset.address div[name$="company"],
    .fieldset.address div[name$="vat_id"],
    .fieldset.address .order-attributes {
      display: none;
    }

    .order-attributes .field {
      padding: 0 15px
    }

    .field-customer-type > .control {
      display: flex;
      gap: 3rem;

      label {
        font-weight: 600;
        font-size: 1.1em;
      }
    }

    .action.action-show-popup {
      margin-left: 15px;
    }
  }

  div[name="shippingAddress.region"] {
    display: none !important;
  }

  div[name="shippingAddress.country_id"] {
    width: 100% !important;
  }

  .field.address-autofill-formatted-output {
    margin-left: 15px;
  }
}
