.experience.grid-gallery {
  h2 {
    font-size: 2.3rem;
    line-height: 2.3rem;
    text-transform: uppercase;
    color: @black;
  }

  hr {
    border-top: 1px solid @green-light;
  }

  .gallery-items {
    margin: 0 -20px;

    img {
      border-radius: 3px;
      object-fit: cover;
      cursor: pointer;
    }
  }
}
