.account {
  a.action[class] {
    color: @green;
  }

  .block-collapsible-nav {
    .content .nav.items .nav.item {
      &.current strong,
      &.current a {
        border-color: @green;
      }
    }
  }
}
