.aw-category {
  &__links--button-wrapper {
    position: relative;

    button {
      height: 32px;
      line-height: 7px;
      padding: .9rem;
      position: absolute;
      top: 2px;

      &:focus{
        outline: none;
      }

      &[disabled] {
        visibility: hidden;
      }

      &:first-of-type {
        left: -7.5px;
      }

      &:last-of-type {
        right: -7.5px;
      }
    }
  }

  &__links--wrapper {
    background: none;
    border: none;
    overflow-x: hidden;

    strong {
      display: block;
      align-self: center;
      padding: 0.5rem;
    }
  }

  &__links[class] {
    margin-bottom: 0;

    li {
      scroll-snap-align: start;
      padding: 0 1rem 0 0;

      &:last-of-type {
        padding-right: 0;
      }

      a {
        text-wrap: nowrap;
        border: solid 1px @btn-bg;
        border-radius: 3px;
      }
    }
  }
}
