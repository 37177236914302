#reset-text() {
	font-family: @font-family-base;
	// We deliberately do NOT reset font-size or word-wrap.
	font-style: normal;
	font-weight: @font-weight-normal;
	line-height: @line-height-base;
	text-align: left; // Fallback for where `start` is not supported
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
}
