@import './_header';
@import './_carousel-content';
@import './_youtube';
@import './_gallery';
@import './_grid-gallery';
@import './_two-column';
@import './_instruction';
@import './_product-slider';

.experience-overview-page,
.experience-detail-page {
  font-size: 1.1rem;

  .aw-content {
    a:not(.button--secondary, .button--primary, .button-cta) {
      font-size: 1.1rem;
    }

    p:empty {
      margin-bottom: 0;
    }
  }
}

.experience-overview-page {
  // Fix for use of widgets in overview page (double container nesting)
  > .row > .col-12 > .aw-content > .experience > .container {
    padding-left: 0;
    padding-right: 0;
  }
}
