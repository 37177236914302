.block.block-search {
  float:none;
  padding-left:0;
}

.aw-search-container,
.aw-mobile-search {
  width: 100%;

  .block-search{
    width: 100%;

    .label::before {
      display:none;
    }

    .control {
      border-top:0;
      input {
        height: 40px;
        border-radius: 2px;
        border: 1px solid @grey50;
        position:relative;
        left:auto;
        margin:0;

        &::placeholder {
          color: @grey100;
          font-style: normal;
        }
      }
    }

    .action.search[data-placeholder] {
      opacity: 1;
      right: 0;
      width: 80px;

      &:before {
        font-size: 13px;
        font-family: @font-body;
        line-height: 39px;
        color: @white;
        background-color: @primary;
        content: attr(data-placeholder);
        width: 100%;
        height: 40px;
        border-radius: 0 2px 2px 0;
        font-weight:600;
        position:relative;
      }
    }
  }
}
