@import '_addtocart.less';
@import '_tabs.less';
@import '_accordion.less';
@import '_description.less';
@import '_usps.less';
@import '_gallery.less';
@import '_price.less';
@import '_tierprice.less';
@import '_owl-vendor.less';
@import '_linkedproducts.less';
@import '_options.less';
@import '_variants.less';
@import '_fsc.less';
@import '_deliverytime.less';
@import '_ugc-gallery.less';
@import '_labels.less';
@import '_amastylabels.less';
@import '_related-blogs.less';
@import '_reviews.less';
@import '_banners.less';

.catalog-product-view {
  .page-main {
    padding: 0;
  }

  .page-main .columns .column.main {
    min-height: unset;
    padding-bottom: 0;
  }

  .container .product-info-main[class] {
    width:100%;
    float:none;
  }

  .product.attribute.overview {
    font-size:1.1rem;
    line-height: 1.6;

    a {
      font-size:1.1rem;
    }
  }

  .product.media {
    width:100%;
    float:none;
    margin-bottom: 15px;

    @media (min-width: @breakpoint-md) {
      margin-bottom: 15px;
    }
  }

  .page-title {
    .h2();
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .price-per-unit {
    margin-bottom:9px;
  }
}
