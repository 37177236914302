.aw-navigation {
  border-bottom: 1px solid @green-light;
}

@media(min-width: @tablet-max-width) {
  .aw-topmenu {
    &__topic {
      &-link[class] {
        font-weight: 600;
        padding: 1.2em 0 1em 0;
        border-bottom: 0.2em solid transparent;
        margin-bottom: -1px;
        transition: border 0.2s ease;

        .aw-topmenu__topic--is-current-page &,
        .aw-topmenu__topic:hover &,
        &:focus {
          background-color: transparent;
          border-bottom-color: @component-border-bottom-color;
        }
      }
    }

    .aw-topmenu__title {
      font-family: @font-body;
      font-weight: 600;

      &:hover {
        background: @primary;
        color: @white;
        border-color: @primary;

        .aw-topmenu__title-icon svg {
          fill: @white;
        }
      }

      &-icon {
        position: relative;
        top: 1px;
        display: inline-block;
        height: 1em;
        width: 1em;
        margin-left: 0.5em;

        svg {
          fill: @primary;
        }
      }

      &-label {
        padding: 2em 1em;
      }
    }

    &__dropdown {
      padding: 3rem 7rem;
      top: 100%;

      &:before {
        background: @white;
        content: '';
        height: 100%;
        width: 150vw;
        position: absolute;
        top: 0;
        left: -50vw;
        z-index: -1;
        border-bottom: 3px solid @component-border-bottom-color;;
        box-shadow: @dropshadow-2;
      }

      &-title {
        display: block;
        font-family: @font-branded;
        text-transform: uppercase;
        font-size: 1.4em;
        font-weight: 500;
        margin-bottom: 2rem;
      }
    }
  }
}

@media(max-width: (@tablet-max-width - 1px)) {
  .aw-topmenu {
    &__topics {
      padding: 0 1rem 1.5rem 1rem;
    }

    &__topic {
      &-image {
        img {
          border: 1px solid @grey;
        }
      }
    }

    &__toggler {
      svg {
        fill: @primary;
        height: 1rem;
        width: 1rem;
      }
    }

    &__section {
      &-image {
        display: none;
      }
    }

    &__additional {
      padding: 1.5rem 1rem;

      ol {
        padding: 0;
        margin: 0 0 2rem 0;
      }

      li {
        border-bottom: 1px solid @green-light;
        margin:0;

        svg {
          position: relative;
          top: 0.2em;
          height: 1.2em;
          width: 1.2em;
          margin-right: 1.2em;
          fill: @primary;
        }
      }

      a {
        display: block;
        padding: 1em;

        &:hover,
        &:focus {
          background: @grey30;
        }
      }
    }

    &__close {
      .button--secondary();
    }

    &__buttons {
      .d-flex();
      .justify-content-end();
    }
  }

  .aw-navigation__mobile {
    top: 157px;
    height: calc(100vh - 157px);

    @media (max-width: @breakpoint-s) {
      top: 136px;
      height: calc(100vh - 136px);
    }
  }
}
