.experience.header {
  //Move the header a bit up to hide whitespace
  margin-top: -7px;

  background: @green ~"url('../images/deer-experience-bg.svg')" no-repeat right;
  background-position-x: 95%;
  background-size: 30%;

  @media(max-width: @breakpoint-md){
    background-size: 80%;
    background-position: center;
    background-position-x: 0;
    text-align: center;
  }

  .container {
    padding-top: 55px;
    padding-bottom: 55px;

    @media(max-width: @breakpoint-md){
      padding-top: 50px;
      padding-bottom: 110px;
    }

    .row {
      .content {
        > h1 {
          color: @white;
          text-transform: uppercase;
        }
        > .intro-text {
          font-weight: 600;
          font-size: 1.2em;
          color: white;
          > p {
            line-height: 2em;
          }
        }
      }
      .image {
        position: relative;
        img {
          position: absolute;
          right: 15px;
          max-height: 300px;
        }
      }
    }
  }
}

.experience.header-image {
  margin-top: -100px;
  padding-left: 15px;
  padding-right: 15px;
}

.experience.breadcrumb.container {
  .breadcrumbs__item--link {
    color: #212529;
    text-decoration: none;
  }
}
