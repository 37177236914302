.catalog-category-view .page-main .page-title {
  .h1();
}

.aw-category {
  &__hero {
    position: relative;
    width: 100%;
    height: 16rem;
    background: @primary;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}