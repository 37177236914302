@import '_success';

.onestepcheckout-index-index {
  .order-summary {
    margin-left:15px;
    margin-right:15px;
    margin-bottom:30px;
    background-color: @green-lighter;
    border: 1px solid @green-light;

    .step-title {
      background-color: @green-lighter;
      border-bottom: 1px solid @green-light;
    }
  }

  .table-totals[class] {
    .totals.sub,
    .totals.shipping,
    .totals-tax-summary {
      border-bottom: 1px solid @green-light;
    }

    .grand.totals.incl {
      border-top-color: @green-light;
    }
  }

  .shipping-address-items {
    .shipping-address-item {
      border: 1px solid @green-light;

      button.edit-address-link {
        color: @primary;
      }

      &.selected-item {
        border: 2px solid @primary;
      }
    }
  }
}

