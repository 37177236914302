.mp-blog-view {
  .post-view .post-list-content .post-post_content {
    & > *:not(div) {
      padding: 0 15px;
      max-width: 920px;
      margin-left: auto;
      margin-right: auto;
    }

    & > ul, & > ol {
      padding-left: 3rem;
    }
  }

  .breadcrumbs__container {
    margin-bottom: 5.5rem;
    max-width: 1080px;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;

    .breadcrumbs__items {
      padding-left: 0;

      a {
        color: @black;
        text-decoration: underline;
      }
    }
  }

  #mpblog-list-container {
    .post-post_content {
      > h1, > h2, > h3, > h4, > h5, > h6 {
        font-family: @font-branded;
        font-weight: bolder;
      }

      h1 {
        font-size: 2.5rem;
      }

      a {
        text-decoration: underline;
      }

      p {
        color: @black-alt;
      }

      table {
        border-collapse: collapse;

        td:first-child {
          padding-left: 0;
        }

        td:last-child {
          padding-right: 0;
        }

        img {
          display: inline-block;
          width: auto;
          height: auto;
        }
      }

      p > a > strong {
        .button--secondary();
        margin: 1rem 0;
      }
    }
  }

  .block-blog-related {
    &.products-same-post {
      padding: 0 15px;
    }

    .related-title-wrapper {
      padding: 0 15px;
    }

    h2 {
      text-transform: uppercase;
      padding-bottom: 1.75rem;
      border-bottom: 1px solid @grey50;
      margin-bottom: 0;
    }

    a[class] {
      text-decoration: none;
    }
  }

  @media (max-width: @breakpoint-sm) {
    table td {
      display: block;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.cms-home {
  .related-title-wrapper {
    padding: 0 15px;

    h2 {
      font-family: @font-branded;
      font-size: 2rem;
      text-transform: uppercase;
      padding-bottom: 1.75rem;
      border-bottom: 1px solid @grey50;
      margin-bottom: 0;
    }
  }

  .block-blog-related.posts-same-topic {
    margin: 0 -15px;

    .row-product-tiles {
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
