.aw-hero {
  &__content {
    background: transparent;
    overflow: hidden;

    &--bg {
      .vdt__roundedcorner();
      background: @primary;
      z-index: 1;
      opacity: 0.8;
      margin-bottom: -10%;
      @media (min-width: @breakpoint-md) {
        border-bottom-right-radius: 7rem;
      }

      svg {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 110%;
        opacity: 0.3;
      }
    }
  }

  &__title,
  &__subtitle,
  &__button {
    z-index: 1;
  }

  &__button {
    margin-left: auto;

    @media (max-width: @breakpoint-md) {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
