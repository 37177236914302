.block-subtitle.filter-subtitle {
  display: none;
}

.active > .filter-options-title {
  &:after {
    content: '';
    transform: rotate(180deg);
  }
}

.filter-options-title {
  font-family: @font-body;
  font-weight: 600;
  font-size: 1em;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    border-top: 0.4rem solid @black;
    transition: transform 0.2s ease;
  }
}

.filter-options-item {
  border-bottom: 1px solid @grey30;
}

.filter-options-content {
  a {
    font-size: 1em;
    font-family: @font-body;
    text-decoration: none;
    color: @filter-item-color;
    display: block;
    padding: 0.25em 0;
    background: transparent;
    transition: background 0.1s ease;

    &:hover {
      background: transparent;

      input[type="checkbox"],
      input[type="radio"] {
        &:not(:checked) + .label {
          &:before {
            border-color: @filter-item-check-bg--hover;
          }

          &:after {
            transform: scale(0.7);
            background: @filter-item-check-bg--hover;
          }
        }

        &:checked + .label:after {
          transform: scale(0.7);
        }
      }
    }
  }

  .item {
    margin: 0;

    input[type="checkbox"],
    input[type="radio"] {
      margin-right: 0.5rem;
    }
  }

  .am-show-more {
    background: transparent;
    color: @black;
    text-decoration: underline;
    padding: 0;
    border: 0;
    margin-top: 1rem;

    &:hover {
      text-decoration: none;
    }
  }

  .am-filter-items-attr_price {

    .am-fromto-widget {
      margin-bottom: 2rem;

      & > * {
        display: inline-block;
      }

      .am-filter-go {
        margin-left: 1rem;
        height: 40px;
      }
    }
  }

  .am-filter-price {
    width: auto;
    max-width: 70px;
    font-size: 1rem;
    line-height: 1em;
    padding: 0.9em 1em;
    height: 40px;
  }

  .range {
    text-align: left;
  }
}

.amshopby-slider-container[class] {
  padding-right: 10px;
  margin: 1rem 0;

  @media (max-width: @breakpoint-md) {
    padding: 0 2rem 0 1rem;
  }

  .am-slider {
    position: relative;
    background: @priceslider-bg;

    .ui-slider {
      position: relative;
    }

    .ui-slider-horizontal {
      position: relative;
      background: @priceslider-bg;
      margin: 1rem 0;
    }

    .ui-slider-range {
      position: relative;
      height: 10px;
      background: @priceslider-range-bg;
    }

    .ui-slider-handle {
      position: absolute;
      display: inline-block;
      top: 2px;
      height: 1.2rem;
      width: 1.2rem;
      background: @priceslider-handle-bg;
      transition: background 0.2s ease;
      border-radius: 50%;

      &:hover {
        background: @priceslider-handle-bg--hover;
        cursor: ew-resize;
      }
    }
  }

  .amshopby-slider-display {
    margin-top: 1rem;
  }
}

.am_shopby_apply_filters {
  display: none;
}


@media (max-width: @breakpoint-lg) {
  body.filter-active {
    .page-wrapper { // reset
      height: auto;
      margin-top: 0;
      visibility: visible;
    }

    .am_shopby_apply_filters {
      display: block;
    }

    .am-show-button {
      text-align: center;
      padding: 15px;

      .am-button,
      .am-items {
        display: block;
        margin: auto;
      }

      .am-button {
        .button--primary();
        width: 100%;
        margin-top: 15px;
      }
    }

    .filter-content {
      display: block;
      background: @white;
      padding: 15px 15px 80px 15px;
    }
    .filter-options {
      display: block;
    }

    .aw-filter--open {
      position: fixed;
      top: 0.5rem;
      left: 15px;
      z-index: 9999;
      font-size: 1.4rem;
      line-height: 3rem;
      padding: 0;
      background: transparent;
      border: 0;
      width: auto;
      color: @white;
    }

    .filter-options-title {
      padding: 10px 30px 0 0;

      &:after {
        top: 16px;
        right: 2px;
      }
    }

    .filter-options-content {
      padding: 10px 0 5px 0;
    }

    .filter-options-item {
      padding-bottom: 10px !important;
    }

    .aw-filter--close {
      position: fixed;
      top: 0.5rem;
      right: 0.5rem;
      display: block;
      width: 3rem;
      height: 3rem;
      padding: 0.8rem;
      z-index: 9999;

      svg {
        fill: @white;
      }
    }

    .filter.active {
      .filter-options {
        position: relative;
        top: 0;
      }
      .filter-subtitle {
        display: none;
      }
    }

    #layered-filter-block {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: @white;
      z-index: 999;
      width: auto;
      border-top: 4rem solid @primary;
      margin-bottom: 0;
      overflow-y: scroll;
    }

    .col-12 > #layered-filter-block {
      display: block;
    }
  }

  .filter-title {
    strong {
      display: none;
    }
  }

  .filter.block {
    margin-bottom: 1rem;
  }

  #am-shopby-container .filter-title,
  .filter-content {
    display: none;
  }

  .aw-filter--close {
    display: none;
  }

  .sidebar {
    position: relative;

    .filter-content {
      position: absolute;
      z-index: 999;
      background: @m-filter-item-bg--active;
      transform: translateX(-110%);
    }

    .filter {
      &.active {
        z-index: 999;
        position: absolute;
        top: 100%;
        left: -10px;
        width: 360px;
        max-width: 100%;
        margin-top: 4rem;

        .aw-filter--close {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 48px;
          height: 48px;
          text-align: center;

          svg {
            display: inline-block;
            height: 100%;
            width: 40%;
            fill: @filter-closebutton-color;
          }

          @media (min-width: @breakpoint-md) {
            display: none;

            .sidebar-main & {
              display: none;
            }
          }
        }

        * {
          box-sizing: border-box;
        }

        .filter-content,
        .filter-title {
          position: relative;
          background: @m-filter-item-bg--active;
          transform: translateX(0%);
          right: auto;
        }

        .filter-subtitle {
          display: none;
        }

        .filter-title {
          display: block;
          height: 48px;
          background: @m-filter-title-bg;
          border-bottom: 0;

          strong {
            display: none;

            &:after {
              display: none;
            }
          }
        }

        .filter-options {
          background: @m-filter-item-bg--active;
          overflow: visible;
        }

        .filter-options-item {
          &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }

    .filter.active {
      .filter-subtitle,
      .filter-title,
      .filter-options {
        position: relative;
        left: 0;
        top: auto;
      }
    }
  }

  .sidebar-options {
    background: @m-filter-item-bg;
  }
}

.aw-filter--open {
  .button--secondary();
  color: @black;
  width: 100%;
  max-width: 180px;

  @media (min-width: @breakpoint-lg) {
    display: none;
  }
}

.toolbar-wrapper-bottom .aw-filter--open {
  display: none;
}

.page-with-filter .toolbar-amount {
  position: relative;
  display: inline-block;
  margin-right: 0.5em;
}

.filter {
  .block-subtitle {
    font-family: @font-body;
    font-size: 1em;
    padding: 10px 10px 20px;
    border-bottom: 1px solid @filter-item-border-color;
  }

  .filter-current-subtitle {
    &:after {
      display: none;
    }
  }

  .filter-current {
    border: 0;
    margin: 2rem 0;

    &.active {
      padding: 0;
    }

    .block-subtitle {
      padding: 0;
      border: 0;
      text-transform: none;

      &:before {
        display: none;
      }
    }

    .items {
      display: block;
      padding: 0;
      margin-top: 0.5rem;
    }

    .item {
      display: inline-block;
      background: @filter-activeitem-bg;
      font-size: 0.9em;
      border: 1px solid @filter-activeitem-border-color;
      padding: 0.5em 1em;
      margin: 0.2em 0.1em;
    }

    .filter-value {
      color: @filter-activeitem-color;
    }

    .action.remove {
      position: relative;
      display: inline-block;
      margin-left: 1em;
      cursor: pointer;
    }

    .aw-clearall-link {
      color: @filter-clearlink-color;
      font-weight: 400;
      margin-left: 1rem;
      font-family: @font-body;
      font-size: 0.8em;
    }
  }
}

//Hide weird Luma hover thingy
.filter-no-options .filter-title:before {
  display: none;
}

.amasty-catalog-topnav[class] {
  display: block;
}

.filter-current-subtitle {
  font-family: @font-body;
  font-weight: 600;
  font-size: 1em;
  text-transform: uppercase;
  margin-right: 1em;
}

.filter-current {

  @media (min-width: @breakpoint-lg) {
    padding: 10px 40px 0 10px;
    border-bottom: 1px solid @grey50;
  }

  .items {
    padding: 1em 0;
  }

  .aw-clearall-link {
    text-decoration: underline;
    font-size: 0.8em;

    &:hover {
      text-decoration: none;
    }
  }
}

#am-shopby-container {
  .action.remove {
    position: relative;
    left: 0;
    top: 1px;
    vertical-align: middle;
    margin-left: 0.5em;

    &:before {
      display: none;
    }

    svg {
      height: 1rem;
      width: 1rem;
    }

    &:hover {
      fill: @red;
    }
  }

  .am-shopby-item[class] {
    display: inline-block;
    padding: 0.5em;
    margin: 0 1em 0.5em 0;
    border: 1px solid @grey50;

    .filter-label::after {
      content: '';
    }
  }
}
