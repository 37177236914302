/// Grid system
//
// Generate semantic grid columns with these mixins.

#make-container() {
	width: 100%;
	padding-right: (@grid-gutter-width / 2);
	padding-left: (@grid-gutter-width / 2);
	margin-right: auto;
	margin-left: auto;
}


// For each breakpoint, define the maximum width of the container in a media query
//@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
//  @each $breakpoint, $container-max-width in $max-widths {
//    @include media-breakpoint-up($breakpoint, $breakpoints) {
//      max-width: $container-max-width;
//    }
//  }
//}
#make-container-max-widths(@max-widths: @container-max-widths, @breakpoints: @grid-breakpoints) {
	#each-max-width(@i: 1) when (@i <= length(@max-widths)) {
		@item: extract(@max-widths, @i);
		@breakpoint: extract(@item, 1);
		@container-max-width: extract(@item, 2);

		#media-breakpoint-up(@breakpoint, {
			max-width: @container-max-width;
		}, @breakpoints);

		#each-max-width((@i + 1));
	} #each-max-width();
}

#make-row() {
	display: flex;
	flex-wrap: wrap;
	margin-right: (@grid-gutter-width / -2);
	margin-left: (@grid-gutter-width / -2);
}

#make-col-ready() {
	position: relative;
	// Prevent columns from becoming too narrow when at smaller grid tiers by
	// always setting `width: 100%;`. This works because we use `flex` values
	// later on to override this initial width.
	width: 100%;
	min-height: 1px; // Prevent collapsing
	padding-right: (@grid-gutter-width / 2);
	padding-left: (@grid-gutter-width / 2);
}

#make-col(@size, @columns: @grid-columns) {
	flex: 0 0 percentage((@size / @columns));
	// Add a `max-width` to ensure content within each column does not blow out
	// the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
	// do not appear to require this.
	max-width: percentage((@size / @columns));
}

#make-col-offset(@size, @columns: @grid-columns) {
	@num: (@size / @columns);

	& when (@num = 0) {
		margin-left: 0;
	}
	& when not (@num = 0) {
		margin-left: percentage(@num);
	}
}
