.aw-tabs {
  color: @text-body-color;
  .nav.nav-tabs {
    .nav-link {
      &.active,
      &:hover {
        border-color: transparent;
        color: @primary;
      }
    }
    border-top: 1px solid @green-light;
    border-bottom: 1px solid @green-light;
  }

  h2 {
    font-family: @font-branded;
    font-size: 2rem;
    font-weight: 300;
    margin: 0.5 0rem;
  }

  .tab-content {
    a {
      color: @primary;
      text-decoration: underline;
    }
  }
}
