.price-box[class]:not(.price-tier_price) {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  .price-wrapper {
    display: block;

    .price {
      color: @primary;
      font-size: 2rem;
      font-family: @font-branded;
      font-weight:400;
    }
  }

  .old-price[class] {
    margin:0;

    .price-container {
      .price-label {
        display: none;
      }

      .price-wrapper .price {
        font-size: 0.95rem;
        text-decoration: line-through;
        color: @text-body-color;
        font-weight: 600;
        font-family: @font-body;
      }
    }
  }

  .price-container.price-final_price {
    .price-label {
      font-size: 0.95rem;
      text-transform: uppercase;
      color: @text-body-color;
      font-weight: 600;
      font-family: @font-body;
    }
  }

  .minimal-price {
    margin:0;

    .price-label {
      font-size: 0.85rem;
      color: @text-body-color;
      font-weight: 600;
      font-family: @font-body;
      text-transform:uppercase;
    }
  }
}

.page-product-configurable {
  .price-box.price-final_price {
    display: flex;
    flex-direction: column;

    .normal-price {
      order: 1;
    }

    .old-price {
      order: 0;
    }
  }
}

#price-per-meter-container {
  margin-bottom: 15px;

  &.hide-on-pageload {
    display: none;
  }
}
