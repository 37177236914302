.amlabel-position-wrapper {
  margin: 15px;
  z-index: 5;
}

.amasty-label-container {
  width: fit-content !important;
  font-family: @font-branded;
  line-height: 50px;

  @media (max-width: @breakpoint-md) {
    line-height: 35px;
  }
}

.amlabel-text {
  position: relative !important;
  font-weight: 600;
  letter-spacing: 1px;
}
