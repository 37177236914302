.aw-topbar {
  background-color: @topbar-bg;

  ul {
    list-style:none;

    li {
      margin: 0;
    }
  }

  a {
    color: @white;
    text-transform: uppercase;
    font-size:0.85rem;
    text-decoration:none;
    font-weight:600;
    transition: all .2s ease-in-out;
    padding-top:10px;
    padding-bottom:10px;
    letter-spacing: 1px;

    &:hover {
      background-color: @white;
      color:@topbar-hover-color;
    }

    &.accent {
      background-color: @topbar-accent-color;

      &:hover {
        background-color: @white;
        color: @topbar-accent-color;
      }
    }
  }
}
