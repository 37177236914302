.buorg[class] {
  border-top: 1px solid @primary;
  background: @grey;
  font-family: @font-body;
  z-index: 16000002;

  .buorg-buttons {
    margin-top: 1rem;

    #buorgul {
      .button--primary()
    }

    #buorgig {
      .button--secondary()
    }
  }
}