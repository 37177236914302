@import '_cartnotification';

.checkout-cart-index {
  .page-title {
    text-transform: uppercase;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .page-main .columns .column.main {
    min-height: unset;
    padding-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
  }

  .payment-methods-cart-container {
    width: 75%;
    float: left;
    padding-right: 4%;
    padding-left: 0;

    @media (max-width: @breakpoint-md) {
      width: 100%;
      float: none;
      padding-right: 0;
    }
  }

  .payment-methods-cart {
    border-bottom: 1px solid @grey30;
  }

  .usps-cart {
    .usps {
      justify-content: start;
      border: 0;

      .usp {
        text-align: left;
        padding: 10px 0;
      }
    }

    @media (max-width: @breakpoint-sm) {
      padding-left: 0;
    }
  }

  .cart-summary-container {
    width: 25%;
    float: right;

    @media (max-width: @breakpoint-md) {
      width: 100%;
      float: none;

      .shipping-information-sidebar {
        margin-bottom: 20px;
        display: inline-block;
      }
    }
  }

  .cart-summary {
    top: 0 !important;
    width: 100%;
    float: none;
    border: 1px solid @grey30;
    padding-left: 0;
    padding-right: 0;
    background-color: @grey10;
  }

  .cart-summary .content {
    display: none;
  }

  .cart-totals[class] {
    border: 0;

    .totals.sub,
    .totals-tax-summary {
      border-bottom: 1px solid @grey30;
    }

    .totals-tax-summary {
      .mark, .amount {
        border: 0;
        cursor: default;
      }

      .price {
        padding-right: 0;

        &::after {
          display: none;
        }
      }
    }

    .grand.totals.incl {
      border-top: 1px solid @grey30;
    }

    .grand.totals.excl {
      display: none;
    }
  }

  .cart-container {
    .form-cart {
      float: none;
      width: 100%;
      padding-right: 0;

      .form-cart-inner {
        border: 1px solid @grey30;
        padding: 20px;
      }

      .table-wrapper {
        margin-bottom: 0;
      }

      @media (min-width: @breakpoint-md) {
        // wtf luma
        width: 75%;
        padding-right: 4%;
      }

      @media (max-width: @breakpoint-md) {
        .table-wrapper {
          border: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .usps {
    flex-direction: column !important;
  }

  .cart-summary > .title {
    border-bottom: 1px solid @grey30;
    padding-left: 15px;
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 1.5em;
  }

  .cart-totals {
    border-top: 1px solid @grey30;
    border-bottom: 1px solid @grey30;
    padding-left: 15px;
    padding-right: 15px;
  }

  .cart-summary {
    .totals.sub.excl,
    .totals.sub.incl,
    .totals-tax {
      border-bottom: 1px solid @grey30;
    }

    .checkout.methods {
      .checkout-speed-information {
        font-size: 0.95rem;
        display: block;
        margin-top: 10px;
      }
    }
  }

  .cart-summary th {
    padding-bottom: 15px;
    padding-top: 15px;
    background-color: transparent;
  }

  .cart-summary td {
    padding-bottom: 15px;
    padding-top: 15px;
    font-weight: 600;
  }

  .checkout-methods-items li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .cart-totals .grand .mark, .grand .mark {
    font-size: 1em;
    border: 0;

    strong {
      margin-top: 10px;
    }
  }

  .cart-totals .grand .price, .grand .amount {
    border: 0;

    .price {
      font-size: 20px;
    }
  }

  .totals-tax-details {
    display: table-row !important;
    border-bottom: 0;

    th {
      font-size: 0.9rem;
    }

    th, td {
      padding-bottom: 8px;
      padding-top: 8px;
    }
  }

  title {
    text-transform: uppercase;

  }

  .cart-container {
    @media (max-width: @breakpoint-md) {
      padding-bottom: 10px;
      padding-right: 20px;
      padding-left: 20px;
    }

    .item-info {
      margin-right: 0;
      margin-left: 0;

      .product-item-name {
        font-size: 1.1rem;

        a {
          font-size: 1.1rem;
          font-weight: 600;
        }
      }

      .item-options[class] {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        font-style: italic;

        dt::after {
          display: none;
        }
      }
    }

    .item {
      .product-item-photo {
        border: 1px solid @grey940;

        @media (max-width: @breakpoint-md) {
          position: static;
        }
      }

      .product-item-photo-wrapper {
        max-width: 125px;
      }

      .product-image-wrapper {
        border: 1px solid @grey30;
        border-radius: 3px;
        background: @grey10;
      }

      .product-image-container {
        width: 100px !important;
        @media (max-width: @breakpoint-lg) {
          width: 75px !important;
          margin: auto;
        }
      }
    }

    .qty[class] {
      display: flex;
      flex-direction: column;
      align-self: center;

      .label {
        display: none;
      }

      &::before {
        content: '';
      }
    }

    .control.qty.control.qty {
      .mage-error[generated] {
        display: none !important;
      }

      input {
        margin-top: 0;
        height: auto;
        width: 50px;
        text-align: center;

        :focus {
          outline: none;
        }
      }
    }

    .product-item-details {
      display: flex;
      flex-direction: column;
      align-self: center;
      padding-bottom: 0;

      .remove-from-cart a {
        color: @primary;
        font-weight: 600;
      }
    }

    .subtotal[class] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100px;
      text-align: right;

      &::before {
        content: '';
      }

      .price-excluding-tax,
      .price-including-tax {
        display: flex;
        flex-direction: column;

        .cart-price {
          font-size: 1.2rem;
        }

        .cart-price.unit {
          margin-top: 5px;

          .price {
            font-weight: 300;
          }
        }
      }

      .subtotal-amount-and-unit-price {
        font-weight: 300;
        font-size: 1.2rem;
        margin-top: 5px;
      }
    }

    .main.actions {
      text-align: left;
      display: flex;

      .action.continue {
        display: inline-block;
        color: @primary;
        text-align: left;

        a {
          color: @primary;
        }
      }

      .action.clear {
        margin-left: auto;
        background-color: transparent;
        color: @primary;
        border: 0;
        padding: 0;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    hr {
      border: 0;
      border-top: 1px solid @green-light;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
}
