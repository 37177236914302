.quotation-options[class] {
  .button {
    color: @white;

    &:hover {
      color: @white;
    }
  }

  .product-item__title {
    height: 50px;
  }

  .subtitle {
    display: block;
    text-transform: uppercase;
    font-family: @font-body;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .label[class][class] {
    margin-top:0;

    &::before {
      top: 9px;
    }

    &::after {
      top: 10px;
    }
  }

  .options {
    li {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .image {
    border-top: 1px solid @grey30;
    border-bottom: 1px solid @grey30;
    margin-bottom:10px;
  }

  .option-check {
    font-size: 0.8rem;
    padding-right: 10px;
    margin-bottom:0;
  }

  .option-price {
    font-size:0.8rem;

    .unit {
      white-space: nowrap;
    }
  }
}

body[class*="page-layout-quotation-form"] {
  .field-options[class][class] {
    label {
      display: none;
    }

    .control {
      input {
        visibility: hidden;
        height:0;
      }

      .mage-error[class][generated] {
        visibility: hidden;
        position: relative;

        &::after {
          content:'U heeft geen type schutting geselecteerd. Meerdere selecties zijn mogelijk.';
          visibility:visible;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  .field-phone input {
    max-width: unset;
  }

  .message[class].success {
    margin: 0 0 1em 0;
  }

  .rendered-form textarea.form-control {
    height: 120px;

    &::placeholder {
      color: @grey100;
    }
  }
}

