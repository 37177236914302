.delivery-time,
.delivery-time-configurable {
  gap: 0.5rem;

  div {
    gap: 0.5rem;
  }

  a {
    text-decoration: underline;
  }

  .icon-small {
    width: 30px;
  }
}
