// Most styles are already reset by Normalize
//
// @link https://github.com/necolas/normalize.css/blob/master/normalize.css#L250
// @link https://github.com/inuitcss/objects.buttons/blob/master/_objects.buttons.scss
//
// 1. Allow us to style box model properties.
// 2. Line different sized buttons up a little nicer.
// 3. Reset/normalize some styles.
// 4. Force hand-pointer when using on non-btn-like elements like <span/>

.button {
  display: inline-block; // [1]
  border-radius: 3px; // [3]
  appearance: none;
  vertical-align: top; // [2]
  text-align: center; // [3]
  text-decoration: none; // [3]
  line-height: 1; // [3]
  cursor: pointer; // [4]
  font-family: @font-body;
  font-weight: 400;
  background: none;
  box-shadow: none;
  padding: 0.9em 1.5em;
  background-color: @btn-bg;
  border: 1px solid @btn-bg;
  color: @btn-color;
  font-size: 1rem;
  height: auto; // reset Luma
  transition: 0.2s ease;

  &:hover,
  &:focus,
  &:active {
    background-color: @btn-hover-bg;
    border: 1px solid @btn-hover-bg;
    text-decoration: none;
    color: @btn-color;
  }

  &:visited {
    color: @btn-color;
  }
}

.button--primary {
  .button();
  background-color: @btn-bg;
  border-color: @btn-bg;
  color: @btn-color;

  &:hover,
  &:focus,
  &:active {
    background-color: @btn-hover-bg;
    border: 1px solid @btn-hover-bg;
    color: @btn-color;
  }
}

.button--secondary {
  .button();
  background-color: @btn-secondary-bg;
  color: @btn-secondary-color;
  border: 1px solid @btn-secondary-outline-color;
  font-family: @font-body;

  &:hover,
  &:focus,
  &:active {
    background-color: @btn-secondary-hover-bg;
    border: 1px solid @btn-secondary-outline-color;
    color: @btn-secondary-color;
  }

  &:visited {
    color: @btn-secondary-color;
  }
}

.button--cta {
  .button();
  background-color:@btn-cta-bg;
  color:@btn-cta-color;
  border: none; // reset Luma
  font-weight: 700;
  padding: 0.45em 1em;
  font-size: 1.1rem;

  &:hover,
  &:focus,
  &:active {
    border: none; // reset Luma
    background-color:@btn-cta-hover-bg;
    color: @btn-cta-color;
  }

  & > svg {
    fill: @btn-cta-color;
  }
}

.button--large {
  width: 100%;
  font-size: 1.1rem;
  padding: 0.9em 1em;
  line-height: 1.3; // override luma
  height: auto; // FU luma
}

.button__vdt {
  .button();
  position: relative;
  background: transparent;
  overflow: hidden;
  border: 0;

  &:after {
    .vdt__roundedcorner();
    background: @btn-cta-bg;
  }

  &:hover {
    background: transparent;
    border: 0;

    &:after {
      background: @btn-cta-hover-bg;
    }
  }
}

// LUMA, I COMMAND THEE, SURRENDER TO MY POWERS
.action.primary[class],
.actions .primary .action.primary,
.actions-toolbar .primary .action-apply,
#confirmButtons .action.primary,
.cart-discount .coupon .primary .action.primary,
.block-authentication .action.action-login,
.block.block-new-customer .action.create.primary,
.form-address-edit .actions-toolbar .action.primary {
  .button--primary();
}

.button--block {
  width: 100%;
}

.action.primary.tocart[class][class][class],
.action.primary.checkout[class][class][class] {
  .button--large();
}

button:not(.primary) {
  &:active {
    box-shadow: none; // reset Luma
  }
}
