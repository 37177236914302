.aw-content {
  color: @text-body-color;

  h1, h2, h3, h4, h5, h6 {
    color: @text-heading-color;
    margin: 1em 0;
  }

  .page-title-wrapper h1 {
    margin-top: 0;
  }

  h1 {
    .h1();
  }
  h2 {
    .h2();
  }
  h3 {
    .h3();
  }
  h4 {
    .h3();
  }
  h5 {
    .h3();
  }
  h6 {
    .h3();
  }

  h3, h4, h5, h6 {
    font-family: @font-body;
  }

  // Needed because the images aligned in wysywig get floats.
  p {
    clear: both;
  }

  // Wysywig only adds float as style. Target the images aligned left and right this way for now.
  img {
    &[style*="float: left"] {
      margin: 0 1rem 1rem 0;
    }
    &[style*="float: right"] {
      margin: 0 0 1rem 1rem;
    }
    @media (max-width: @breakpoint-s) {

      &[style*="float: left"],
      &[style*="float: right"] {
        float: none;
        width: 100%;
      }
    }
  }

  ul,
  ol {
    margin-bottom: 1rem;
    padding-left: 2rem;

    & > li {
      margin-bottom: 0;

      & > ul {
        margin-bottom: 0.2rem;
      }
    }
  }

  a:not(.button--secondary, .button--primary, .button-cta) {
    .generic-link();
    line-height: 1.6em;
  }
  a[href*=".pdf"] {
    .button--secondary();
  }

  p, li {
    line-height: 1.6em;
  }

  iframe {
    max-width: 100%;
  }

  blockquote {
    font-size: 1em;
    line-height: inherit;
    padding-left: 1rem;
    margin-left: 1rem;
    border-left: 3px solid @primary;
  }

  table {
    margin-bottom: 1rem;

    th,
    td {
      border-bottom: 2px solid @component-border-color-accent;
    }

    th {
      background: @component-border-color;
    }
  }

  .aw-iframewrap {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    display: block;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .g-recaptcha .aw-iframewrap {
    position: static;
    padding: 0;

    iframe {
      position: static;
      height: 80px;
    }
  }
}

.section {
  padding: 2rem 0;

  &__title {
    .h2();
    text-transform: uppercase;
    margin: 0 0 2rem 0;
  }
}

.page-main {
  .container();
}

.columns {
  .row();
}

.cms-page-view {
  .columns {

  }
  .column.main {
    .col-12();
    .m-auto();

    @media (min-width: @breakpoint-md) {
      .col-8();
    }
  }
}

.content-menu {
  list-style: none;
  padding-left: 0;

  > li:first-child {
    font-weight: 600;
    font-size: 0.9rem;
  }

  &__item {
    &.current a
    {
      font-weight: 600;
      color: @text-heading-color;
    }
  }
}
