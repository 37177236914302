@import '_toolbar.less';
@import '_filters.less';
@import '_categorymenu.less';
@import '_categoryhead.less';
@import '_links.less';
@import '_experience.less';
//@import '_product-grid.less';
//@import '_landing.less';

.catalog-category-view {
  .page-main .columns .column.main {
    min-height: unset;
    padding-bottom: 0;
  }

  .page-title {
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }

  .columns {
    padding-top: 0;
  }
}

.subcategories {
  &__title {
    text-transform: uppercase;
    font-family: @font-branded;
    border-bottom: 1px solid @grey100;
    padding-bottom:25px;
  }

  &__container {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}
